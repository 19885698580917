define("managing-tool-frontend/we-manage/administration/customers/customer/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      deleteCustomer: function (customer) {
        this.transitionToRoute("weManage.administration.customers");
        this.store.findRecord("customer", customer.id).then(function (nobody) {
          nobody.destroyRecord();
        });
      },
      editCustomer: function () {
        this.transitionToRoute("weManage.administration.customers.customer.edit");
      },
      showMachines: function () {
        this.transitionToRoute("weManage.administration.customers.customer.machines");
      },
      showLocations: function () {
        this.transitionToRoute("weManage.administration.customers.customer.locations");
      }
    }
  });

  _exports.default = _default;
});