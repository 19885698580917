define("managing-tool-frontend/serializers/diagnosis", ["exports", "managing-tool-frontend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function (record, options) {
      let json = this._super(record, options);

      json.incident = parseInt(json.incident);
      return json;
    }
  });

  _exports.default = _default;
});