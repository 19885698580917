define("managing-tool-frontend/helpers/task-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taskState = taskState;
  _exports.default = void 0;

  function taskState(params) {
    let typeInt = parseInt(params);
    let state = "Undefined";

    if (typeInt === 1) {
      state = "New";
    } else if (typeInt === 2) {
      state = "Accepted";
    } else if (typeInt === 3) {
      state = "Canceled";
    } else if (typeInt === 5) {
      state = "Processing";
    } else if (typeInt === 6) {
      state = "Pending";
    } else if (typeInt === 7) {
      state = "Closed";
    }

    return state;
  }

  var _default = Ember.Helper.helper(taskState);

  _exports.default = _default;
});