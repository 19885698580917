define("managing-tool-frontend/helpers/priority-readable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.priorityReadable = priorityReadable;
  _exports.default = void 0;

  function priorityReadable(params) {
    let typeInt = parseInt(params);
    let state = "critical";

    if (typeInt === 1) {
      state = "normal";
    } else if (typeInt === 2) {
      state = "high";
    } else if (typeInt === 3) {
      state = "critical";
    }

    return state;
  }

  var _default = Ember.Helper.helper(priorityReadable);

  _exports.default = _default;
});