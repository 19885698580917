define("managing-tool-frontend/we-manage/administration/customers/customer/locations/location/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      deleteLocation: function (location) {
        this.transitionToRoute("weManage.administration.customers.customer.locations");
        this.store.findRecord("location", location.id).then(function (nothing) {
          nothing.destroyRecord();
        });
      },
      editLocation: function () {
        this.transitionToRoute("weManage.administration.customers.customer.locations.location.edit");
      }
    }
  });

  _exports.default = _default;
});