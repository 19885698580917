define("managing-tool-frontend/we-manage/service-management/task-management/task/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function (controller, model) {
      this._super(controller, model);

      let incident = model.get('incident');
      controller.set('incident', incident);
    },
    renderTemplate: function () {
      this.render("weManage/serviceManagement/taskManagement/task/edit", {
        into: "weManage/serviceManagement/taskManagement",
        outlet: "handleTask"
      });
    }
  });

  _exports.default = _default;
});