define("managing-tool-frontend/we-manage/service-processing/task-management/task/controller", ["exports", "jquery", "managing-tool-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global  WeREPORT */
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    serviceProcessing: Ember.inject.controller('weManage.serviceProcessing'),
    isAcceptable: Ember.computed("taskState", function () {
      return this.get("taskState") !== 1;
    }),
    isRejectable: Ember.computed("taskState", function () {
      return this.get("taskState") !== 1;
    }),
    isProcessable: Ember.computed("taskState", function () {
      let state = this.get("taskState");

      if (state === 2 || state === 6) {
        return false;
      } else {
        return true;
      }
    }),
    isPauseable: Ember.computed("taskState", function () {
      return this.get("taskState") !== 5;
    }),
    isCloseable: Ember.computed("taskState", function () {
      return this.get("taskState") !== 5;
    }),
    actions: {
      acceptButton: function () {
        (0, _jquery.default)('#taskAcceptButton').tooltip('hide');
        let task = this.get("model");
        task.set("state", 2);
        this.set("taskState", 2);
        task.save().then(function (savedTask) {
          savedTask.hasMany("taskLogs").reload();
          savedTask.get("incident").then(function (incident) {
            incident.set("state", 7);
            incident.save().then(function (savedIncident) {
              savedIncident.hasMany("incidentLogs").reload();
            });
          });
        });
        this.set("incidentState", 7);
      },
      rejectButton: function () {
        (0, _jquery.default)('#taskRejectButton').tooltip('hide');
        (0, _jquery.default)("#rejectTaskModal").modal("show");
      },
      reallyReject: function () {
        let task = this.get("model");
        task.set("state", 3);
        this.set("taskState", 3);
        this.set("incidentState", 6);
        task.save().then(function (savedTask) {
          savedTask.hasMany("taskLogs").reload();
          savedTask.get("incident").then(function (incident) {
            incident.set("state", 6);
            incident.save().then(function (savedIncident) {
              savedIncident.hasMany("incidentLogs").reload();
            });
          });
        });
      },
      processButton: function () {
        (0, _jquery.default)('#taskProcessingButton').tooltip('hide');
        let that = this;
        let task = this.get("model");
        let thisUser = this.get("session").get('data.user');
        this.store.query("task", {
          assignedUserID: thisUser.id,
          states: '5',
          limit: 10,
          offset: 0
        }).then(function (allTasks) {
          if (allTasks.length > 1) {
            alert('Task Error: more than one active task!');
          }

          let oldActiveTask = allTasks.get('firstObject');

          if (oldActiveTask) {
            oldActiveTask.set("state", 6);
            oldActiveTask.save().then(function (savedTask) {
              savedTask.hasMany("taskLogs").reload();
            });
          }
        });
        task.set("state", 5);
        this.set("taskState", 5);
        task.save().then(function (savedTask) {
          savedTask.hasMany("taskLogs").reload();
        });
        Ember.run.later(function () {
          if (WeREPORT) {
            window.location.assign("https://" + _environment.default.reportHome);
          } else {
            that.transitionToRoute("weManage.serviceProcessing.taskProcessing");
          }
        }, 1500);
      },
      pauseButton: function () {
        (0, _jquery.default)('#taskPauseButton').tooltip('hide');
        let task = this.get("model");
        task.set("state", 6);
        this.set("taskState", 6);
        task.save().then(function (savedTask) {
          savedTask.hasMany("taskLogs").reload();
        });
      },
      finishButton: function () {
        (0, _jquery.default)('#taskCompleteButton').tooltip('hide');
        let that = this;
        Ember.run.later(function () {
          that.transitionToRoute("weManage.serviceProcessing.taskManagement.task.conclusion");
        }, 1500);
      }
    }
  });

  _exports.default = _default;
});