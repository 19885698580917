define("managing-tool-frontend/we-manage/service-management/incident-management/incident/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      (0, _jquery.default)(function () {
        (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();
      });
    },

    model: function (params) {
      return this.store.findRecord("incident", params.incident_id);
    },
    afterModel: function (model) {
      let allModels = this.modelFor("weManage.serviceManagement.incidentManagement");
      allModels.incidents.forEach(function (incident) {
        incident.set("selected", false);
      });
      model.set("selected", true);
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set("incident", model);
      let state = model.get("state");
      controller.set("state", state);
      model.get("machine").then(function (machine) {
        controller.set("machine", machine);
        machine.get("customer").then(function (customer) {
          controller.set("customer", customer);
        });
      });
    },
    renderTemplate: function () {
      this.render("weManage/serviceManagement/incidentManagement/incident", {
        into: "weManage/serviceManagement/incidentManagement",
        outlet: "handleIncident"
      });
      this.render("customerdetails", {
        into: "weManage/serviceManagement/incidentManagement",
        outlet: "incidentAlpha"
      });
      this.render("machinedetails", {
        into: "weManage/serviceManagement/incidentManagement",
        outlet: "incidentBeta"
      });
    },

    deactivate() {
      (0, _jquery.default)('[data-toggle="tooltip"]').tooltip('hide');
    }

  });

  _exports.default = _default;
});