define("managing-tool-frontend/we-manage/customer/incident-create/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    customerController: Ember.inject.controller('weManage.customer'),

    init() {
      this._super(...arguments);

      this.allPriority = ["NORMAL", "HIGH", "CRITICAL"];
    },

    selPriority: null,
    selMachine: null,
    allMachines: Ember.computed("machine", function () {
      let allFilteredMachines = [];
      this.store.findAll("machine").then(function (allMachines) {
        allMachines.forEach(function (machine) {
          allFilteredMachines.pushObject(machine);
        });
      });
      return allFilteredMachines;
    }),
    dateTimeNow: Ember.computed('', function () {
      return (0, _moment.default)().format();
    }),
    actions: {
      createButton: function () {
        let jsonAppointment = new Date().toJSON();
        let selPriority = this.get("selPriority");

        if (selPriority === "HIGH") {
          this.set("selPriority", 2);
        } else if (selPriority === "CRITICAL") {
          this.set("selPriority", 3);
        } else {
          this.set("selPriority", 1);
        }

        let chosenDate = this.get("chosenDate");

        if (chosenDate) {
          jsonAppointment = chosenDate.toJSON();
        }

        let selMachine = this.get("selMachine");

        if (selMachine === null) {
          selMachine = this.get("allMachines").get("firstObject");
        }

        let newIncident = this.store.createRecord("incident", {
          subject: this.get("subject"),
          description: this.get("description"),
          state: 1,
          priority: this.get("selPriority"),
          appointment: jsonAppointment,
          machine: selMachine
        });
        newIncident.save();
        this.set("subject", "");
        this.set("description", "");
        this.set("selMachine", null);
        this.get("customerController").send("reloadModel");
        return this.transitionToRoute("weManage.customer");
      },
      abortButton: function () {
        this.set("subject", "");
        this.set("description", "");
        this.set("selMachine", null);
        return this.transitionToRoute("weManage.customer");
      }
    }
  });

  _exports.default = _default;
});