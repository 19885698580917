define("managing-tool-frontend/serializers/machine", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    serialize: function (record, options) {
      let json = this._super(record, options);

      json.customer = parseInt(json.customer);
      json.machineType = parseInt(json.machineType);
      return json;
    }
  });

  _exports.default = _default;
});