define("managing-tool-frontend/we-manage/administration/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    AisActive: false,
    CisActive: false,
    TisActive: false,

    switchBreadcrumb(thatActive) {
      let that = this;
      this.set('AisActive', false);
      this.set('CisActive', false);
      this.set('TisActive', false);

      switch (thatActive) {
        case 1:
          that.set("AisActive", true);
          break;

        case 2:
          that.set("CisActive", true);
          break;

        case 3:
          that.set("TisActive", true);
          break;
      }
    },

    actions: {
      switchBreadcrumb: function (thatActive) {
        this.switchBreadcrumb(thatActive);
      },
      loadAccounts: function () {
        this.transitionToRoute("weManage.administration.accounts");
      },
      loadCustomers: function () {
        this.transitionToRoute("weManage.administration.customers");
      },
      loadMachinetypes: function () {
        this.transitionToRoute("weManage.administration.machinetypes");
      }
    }
  });

  _exports.default = _default;
});