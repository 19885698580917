define("managing-tool-frontend/we-manage/service-management/history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SH0i4lzM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-6 offset-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"card-header text-white bg-primary\"],[9],[0,\"\\n        \"],[1,[27,\"fa-icon\",[\"hourglass-end\"],null],false],[0,\" \"],[1,[27,\"t\",[\"history\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"historyIntroductionOne\"],null],false],[0,\".\"],[7,\"br\"],[9],[10],[0,\"\\n        \"],[1,[27,\"t\",[\"historyIntroductionTwo\"],null],false],[0,\".\"],[7,\"br\"],[9],[10],[0,\"\\n        \"],[1,[27,\"t\",[\"chooseHistoryArea\"],null],false],[0,\":\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"card-footer\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-outline-info\"],[9],[1,[27,\"fa-icon\",[\"folder-open\"],null],false],[0,\" \"],[1,[27,\"t\",[\"incident.history\"],null],false],[3,\"action\",[[22,0,[]],\"managementIncidentHistory\"]],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-outline-info\"],[9],[1,[27,\"fa-icon\",[\"tasks\"],null],false],[0,\" \"],[1,[27,\"t\",[\"task.history\"],null],false],[3,\"action\",[[22,0,[]],\"managementTaskHistory\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/we-manage/service-management/history/template.hbs"
    }
  });

  _exports.default = _default;
});