define("managing-tool-frontend/we-manage/customer/incident-detail/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      (0, _jquery.default)(function () {
        (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();
      });
    },

    model: function (params) {
      return this.store.findRecord("incident", params.incident_id);
    },
    afterModel: function (model) {
      this.modelFor("weManage.customer").incidents.forEach(function (incident) {
        incident.set("selected", false);
      });
      model.set("selected", true);
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      try {
        let incidentId = model.get("id");
        this.store.findRecord("incident", incidentId).then(function (incident) {
          controller.set("incident", incident);
          let state = incident.get("state");
          controller.set("state", state);
          incident.get("machine").then(function (machine) {
            controller.set("machine", machine);
          });
        });
      } catch (err) {
        console.error('catch error on load incident at "setupController": ', err);
      }
    },
    renderTemplate: function () {
      this.render("weManage/customer/incidentDetail", {
        into: "weManage/customer",
        outlet: "handleIncident"
      });
      this.render("incidentdetails", {
        into: "weManage/customer",
        outlet: "incidentAlpha"
      });
      this.render("machinedetails", {
        into: "weManage/customer",
        outlet: "incidentBeta"
      });
    },

    deactivate() {
      (0, _jquery.default)('[data-toggle="tooltip"]').tooltip('hide');
    }

  });

  _exports.default = _default;
});