define("managing-tool-frontend/components/userinfo-panel/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    customer: Ember.computed("user", function () {
      let role = this.get("user").get("role");
      return (role & 16) !== 0;
    }),
    editable: Ember.computed("edit", function () {
      return this.edit;
    }),
    actions: {
      toggleUserPanel: function () {
        let displayNow = document.getElementById("UserDetail");

        if (displayNow.style.display === "none") {
          displayNow.style.display = "table";
        } else {
          displayNow.style.display = "none";
        }
      },
      deleteUser: function (user) {
        (0, _jquery.default)("#deleteUserModal").modal("hide");
        this.deleteUser(user);
      },
      editUser: function () {
        this.editUser();
      }
    }
  });

  _exports.default = _default;
});