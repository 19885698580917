define("managing-tool-frontend/we-manage/administration/machinetypes/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    machinetypesController: Ember.inject.controller('weManage.administration.machinetypes'),
    actions: {
      saveAndGoBack: function () {
        let newMachineType = this.store.createRecord("machineType", {
          name: this.get("name"),
          category: this.get("category")
        });
        newMachineType.save();
        this.set("name", "");
        this.set("category", "");
        this.get("machinetypesController").send("reloadModel");
        return this.transitionToRoute("weManage.administration.machinetypes");
      },
      abortButton: function () {
        this.set("name", "");
        this.set("category", "");
        return this.transitionToRoute("weManage.administration.machinetypes");
      }
    }
  });

  _exports.default = _default;
});