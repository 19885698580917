define("managing-tool-frontend/we-manage/service-management/task-management/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      tasksTasksOffset: {
        refreshModel: true
      }
    },

    activate() {
      this.controllerFor("weManage.serviceManagement").send("switchBreadcrumb", 3);
      this.controllerFor("weManage.serviceManagement.taskManagement").set("tasksCurrentPage", 1);
    },

    renderTemplate() {
      this.render("weManage/serviceManagement/taskManagement", {
        into: "weManage",
        outlet: "maincontent"
      });
    },

    model(params) {
      return this.store.query("task", {
        states: '1!2!5!6',
        limit: 6,
        offset: params.tasksTasksOffset
      }).then(tasks => {
        return {
          tasks: tasks,
          tasksM: tasks.get("meta")
        };
      });
    },

    afterModel({
      tasks
    }) {
      tasks.forEach(function (task) {
        task.set("selected", false);
      });
    },

    setupController(controller, {
      tasks,
      tasksM
    }) {
      this._super(controller, {
        tasks,
        tasksM
      });

      controller.set("tasksTotalPages", Math.ceil(tasksM.count / 6));
    },

    redirect({
      tasks
    }, transition) {
      if (transition.targetName === this.routeName + ".index") {
        if (tasks != null) {
          if (tasks.get("length") > 0) {
            this.transitionTo("weManage.serviceManagement.taskManagement.task", tasks.get("firstObject"));
          }
        }
      }
    },

    actions: {
      loadTask(task) {
        this.transitionTo("weManage.serviceManagement.taskManagement.task", task);
      },

      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});