define("managing-tool-frontend/translations/de-de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "abort": "abbrechen",
    "addComment": "Kommentar hinzufügen",
    "admin": "Admin",
    "administration": "Administration",
    "annotate": "Anmerkung",
    "answer": "Antwort",
    "appointment": "Termin",
    "assignedTechnician": "zugewiesener Techniker",
    "back": "zurück",
    "cancel": "abbrechen",
    "categoryName": "Kategorie Name",
    "changeRole": "Ändere deine Rolle",
    "changeToRole": "Um deine aktive Rolle zu ändern wähle eine dir zugeordnete Rolle",
    "chat": "Chat",
    "chooseHistoryArea": "Bitte wähle deinen gewünschte Historien Bereich",
    "city": "Stadt",
    "close": "schliessen",
    "commentExplanation": "Schreibe deine Notizen die du kommentieren möchtest",
    "comments": "Kommentare",
    "companyName": "Firmenname",
    "country": "Land",
    "create": "erstellen",
    "created": "erstellt",
    "creator": "Ersteller",
    "creatorCreated": "Ersteller/erstellt",
    "customer": {
      "customer": "Kunde",
      "customers": "Kunden",
      "delete": "Kunde löschen",
      "deleteQuestion": "Möchtest du wirklich den Kunden löschen, Namens",
      "edit": "Kunde ändern",
      "info": "Kundeninfo",
      "locations": "Kundenorte",
      "machines": "Kundenmaschinen",
      "new": "neuer Kunde"
    },
    "date": "Datum",
    "defaultRole": "standard Rolle",
    "delete": "löschen",
    "description": "Beschreibung",
    "diagnosis": "Diagnose",
    "edit": "Ändern",
    "email": "Email",
    "finalReport": "Abschlussreport",
    "firstname": "Vorname",
    "hide": "verstecken",
    "history": "Historie",
    "historyIntroductionOne": "Willkommen im Service Management Historien Bereich dieser App",
    "historyIntroductionTwo": "In diesem Bereich können Sie sich alle Störfälle und Aufgaben ansehen die Sie jemals angenommen, koordiniert, abgelehnt oder abgeschlossen haben.",
    "id": "ID",
    "in": "in",
    "inProgress": "in Bearbeitung",
    "incident": {
      "abortCreate": "Störfall erstellen abbrechen",
      "abortCreateIncident": {
        "explanation": "bricht die erstellung dieses Störfalles ab",
        "title": "Störfall erstellen abbrechen"
      },
      "accept": {
        "explanation": "akzeptiere diesen Störfall als komplett abgeschlossen",
        "title": "Akzeptieren"
      },
      "active": "aktive Störfälle",
      "askCreateIncident": {
        "explanation": "Anfrage an den Kunden einen Störfall zu erstellen",
        "title": "Störfall erstellen anfrage"
      },
      "close": {
        "explanation": "schliesst den Störfall als komplett erledigt ab",
        "title": "Störfall abschliessen"
      },
      "confirm": {
        "explanation": "bestätige das der Störfall tatsäclich existiert",
        "title": "Bestätigen"
      },
      "confirmCreate": "bestätige Störfall erstellen",
      "confirmCreateIncident": {
        "explanation": "erstellen diesen Störfall definitiv",
        "title": "Störfall erstellen bestätigen"
      },
      "create": "erstelle Störfall",
      "detail": "Störfall Detail",
      "history": "vergangene Störfälle",
      "inProgress": "Störfälle in Arbeit",
      "incident": "Störfall",
      "incidents": "Störfälle",
      "info": "Störfall Info",
      "interrupt": {
        "explanation": "Anfrage diesen Störfall abzubrechen",
        "title": "Unterbrechen"
      },
      "invalid": {
        "explanation": "lehne diesen Störfall ab",
        "title": "Ungültig"
      },
      "management": "Störfall Management",
      "noActive": "keine aktiven Störfälle",
      "reject": {
        "explanation": "lehne diesen Störfall als NICHT erledigt ab",
        "title": "Ablehnen"
      },
      "rejected": "abgelehnte Störfälle",
      "selected": "ausgewählter Störfall",
      "source": "Quell Störfall",
      "switchTo": "schalte zu Störfälle",
      "unedited": "unbearbeitete Störfälle",
      "verify": {
        "explanation": "fragt den Kunden, ob der Störfall komplett erledigt ist",
        "title": "bestätige Erledigung"
      },
      "verifys": "bestätigte Störfälle"
    },
    "intro": {
      "admin": {
        "description": "Der Administrator hat die Möglichkeit übergeordnete Daten, wie zum Beispiel allgemeine Kundeninformationen, in die Applikation einzupflegen und zu verwalten. Der Administrator ist in der Regel ein Mitarbeiter des Anlagenanbieters.",
        "title": "Administrator"
      },
      "contact": "Kontakt",
      "demoAccess": "Sie wünschen einen Online-Demo Zugang",
      "demoDescription": "Falls Sie Informationsmaterial, Beratung oder ein Angebot wünschen, schreiben Sie einfach eine eMail an die nebenstehende Adresse.",
      "home": "Home",
      "imprint": "Impressum",
      "lTechnician": {
        "description": "Der Vor-Ort-Techniker kann entweder ein Mitarbeiter des Maschinennutzers oder der Servicetechniker des Maschinenherstellers sein.",
        "title": "Local Techniker"
      },
      "mail": "sales@webware-experts.de",
      "manager": {
        "description": "Der Manager verwaltet sämtliche Störfälle und Aufgaben, die seinem Unternehmen betreffen. Er nimmt die Störmeldungen der Kunden entgegen und weißt seinen Mitarbeitern diese zu, um die Probleme schnellst möglich aus der Welt zu schaffen.",
        "title": "Manager"
      },
      "rTechnician": {
        "description": "Der Remoteexperte kann an seinem Schreibtisch-PC arbeiten und die Whiteboard-Applikation über einen herkömmlichen Browser nutzen.",
        "title": "Remote Techniker"
      },
      "title": "Service Managing",
      "titleDescriptionOne": "Die Service Managing Software ermöglicht neue Wege der effektiven Verwaltung",
      "titleDescriptionTwo": "von Vor-Ort-Techniker und Remoteexperten.",
      "toggleNavigation": "Navigation umschalten",
      "we": "Copyright &copy; Webware Experts OHG 2017"
    },
    "introductionOne": "Willkommen im Administration-Teil dieser App",
    "introductionTre": "Wähle bitte deinen gewünschten Bereich",
    "introductionTwo": "In diesem Bereich können Sie alle Werte der bestehenden Nutzer und Kunden ändern und auch komplett neue Nutzer und Kunden erzeugen.",
    "kindOfCategory": "Art der Kategorie",
    "lTechnician": "L. Techniker",
    "lastname": "Nachname",
    "latitude": "Latitude",
    "location": {
      "delete": "Standort löschen",
      "deleteQuestion": "Möchtest du wirklich den Standort löschen, Namens",
      "detail": "Standortdetails",
      "edit": "Standort ändern",
      "locations": "Standorte",
      "new": "neuer Standort"
    },
    "login": "Einloggen",
    "logout": "Ausloggen",
    "longitude": "Longitude",
    "machine": {
      "delete": "Maschine löschen",
      "deleteQuestion": "Möchtest du wirklich die Maschine löschen, Namens",
      "detail": "Maschinendetails",
      "edit": "Maschine ändern",
      "machine": "Maschine",
      "machines": "Maschinen",
      "new": "neue Maschine",
      "newType": "neuer Maschinentyp",
      "noActive": "keine aktiven Maschinen verfügbar, daher unmöglich einen Störfall zu erstellen",
      "type": "Maschinetyp",
      "types": "Maschinentypen"
    },
    "manager": "Manager",
    "message": "Nachricht",
    "name": "Name",
    "newComment": "Kommentare erstellen",
    "next": "nächste",
    "no": "no",
    "noComments": "keine Kommentare geschrieben",
    "noDiagnosis": "keine Diagnose erstellt",
    "note": "Notiz",
    "number": "Nummer",
    "overview": "Übersicht",
    "pagination": "Pagination",
    "password": "Passwort",
    "phone": "Telefon",
    "postcode": "PLZ",
    "previous": "bevor",
    "priority": "Priorität",
    "question": "Frage",
    "rTechnician": "R. Techniker",
    "reallyDelete": "wirklich löschen",
    "redirectShortly": "Du wirst in kürze umgeleitet",
    "reset": "zurücksetzen",
    "role": "Rolle",
    "save": "Sichern",
    "send": "Senden",
    "serialNumber": "Seriennumber",
    "show": "zeigen",
    "state": "Bundesland",
    "status": "Status",
    "street": "Straße",
    "subject": "Betreff",
    "suggest": "vorschlagen",
    "switchLanguage": "Sprache",
    "switchRole": "Rolle tauschen",
    "task": {
      "abort": {
        "explanation": "die Arbeit an dieser Aufgabe abbrechen",
        "really": "Möchten Sie die Arbeit an dieser Aufgabe wirklich abbrechen?",
        "title": "Abbrechen"
      },
      "abortCreate": "Aufgabe erstellen abbrechen",
      "abortEdit": "Aufgabe ändern abbrechen",
      "accept": {
        "explanation": "Akzeptiere diese Aufgabe als deine",
        "title": "Akzeptieren"
      },
      "actions": "Aktionen mit dieser Aufgabe",
      "active": "aktive Aufgaben",
      "all": "alle Aufgaben",
      "askCustomer": {
        "explanation": "befragt den Kunden ob diese Aufgabe wirklich beendet ist",
        "title": "Kunden fragen"
      },
      "cancel": {
        "explanation": "brich die Fertigstellung dieser Aufgabe ab",
        "title": "abbrechen"
      },
      "cancelWork": {
        "explanation": "brich die Arbeit an dieser Aufgabe ab",
        "title": "Abbrechen"
      },
      "clear": {
        "explanation": "leere das komplette Diagnose Feld",
        "title": "leeren"
      },
      "closingWork": {
        "explanation": "schliesse diese Aufgabe als erledigt",
        "title": "Schliessen"
      },
      "complete": {
        "explanation": "komplettiere diese Aufgabe",
        "title": "Komplettieren"
      },
      "conclusion": "Aufgabeabschluss",
      "confirmCreate": "Aufgabe erstellen bestätigen",
      "confirmEdit": "Aufgabe ändern bestätigen",
      "createTask": {
        "explanation": "Erstelle eine neue Aufgabe für den selektierten Störfall",
        "title": "Aufgabe erstellen"
      },
      "current": "aktuelle Aufgabe",
      "edit": {
        "explanation": "Ändere die Eigenschaften dieser Aufgabe",
        "title": "Ändern"
      },
      "events": "Ereignisse mit dieser Aufgabe",
      "finish": {
        "explanation": "diese Aufgabe erfolgreich abschliessen",
        "title": "Abschliessen"
      },
      "finished": {
        "explanation": "schliesse diese Aufabe als vollständig abgearbeitet",
        "title": "Vollendet"
      },
      "history": "Aufgaben Historie",
      "management": "Aufgaben Management",
      "noActive": "keine aktive Aufgaben",
      "noProgress": "keine Aufgabe in bearbeitung",
      "pause": {
        "explanation": "die Arbeit an dieser Aufgabe pausieren",
        "title": "Pause"
      },
      "process": {
        "explanation": "beginn mit der Arbeit an dieser Aufgabe",
        "title": "Bearbeiten"
      },
      "processing": "Aufgabenbearbeitung",
      "reassign": {
        "explanation": "weise diese Aufgabe einem anderen Techniker zu",
        "title": "Neu zuweisen"
      },
      "reject": {
        "explanation": "lehne diese Aufgabe ab",
        "really": "Möchten Sie diese Aufgabe wirklich abbrechen",
        "title": "Ablehnen"
      },
      "selected": "ausgewählte Aufgabe",
      "submit": {
        "explanation": "übertrage die Diagnose",
        "title": "Übermitteln"
      },
      "switchTo": "zu Aufgabe wechseln",
      "task": "Aufgabe",
      "tasks": "Aufgaben"
    },
    "technician": "Techniker",
    "technicianOverview": "Techniker Übersicht",
    "time": "Zeit",
    "toggleNavigation": "Navigation umschalten",
    "unseen": "ungesehen",
    "user": {
      "deleteQuestion": "Möchtest du wirklich den Nutzer löschen, Namens",
      "edit": "Nutzer ändern",
      "info": "Nutzer Info",
      "new": "neuer User",
      "user": "Nutzer",
      "users": "Nutzer"
    },
    "weManage": "weManage",
    "weManageLogo": "weManage Logo",
    "year": "Baujahr",
    "yes": "yes",
    "youAreA": "Aktuell bist du"
  };
  _exports.default = _default;
});