define("managing-tool-frontend/we-manage/service-management/history/incidents/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['historyIncidentsOffset'],
    incidentsCurrentPage: 1,
    incidentsTotalPages: 0,
    historyIncidentsOffset: 0,
    actions: {
      changePage(whatWhere) {
        let page = this.get('incidentsCurrentPage');

        if (whatWhere == 1) {
          this.set('incidentsCurrentPage', page + 1);
          this.set('historyIncidentsOffset', page * 6);
        } else {
          this.set('incidentsCurrentPage', page - 1);
          this.set('historyIncidentsOffset', (page - 2) * 6);
        }
      }

    }
  });

  _exports.default = _default;
});