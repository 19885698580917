define("managing-tool-frontend/we-manage/service-processing/task-processing/task-details/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    diagnosisText: Ember.computed("incident.diagnosis", function () {
      return this.get("incident").get("diagnosis");
    }),
    actions: {
      createIncidentDiagnosis: function () {
        (0, _jquery.default)('[data-toggle="tooltip"]').tooltip('hide');
        let incident = this.get("incident");
        let newDiagnosis = this.store.createRecord("diagnosis", {
          text: this.get("incidentDiagnosis"),
          incident: incident
        });
        newDiagnosis.save();
        this.set("incidentDiagnosis", "");
        incident.set("diagnosis", newDiagnosis);
      },
      clearDiagnosisTextarea: function () {
        this.set("incidentDiagnosis", "");
      },
      toggleTaskDiagnosisPanel: function () {
        let displayNow = document.getElementById("taskProcessingDiagnosisDetail");

        if (displayNow.style.display === "none") {
          displayNow.style.display = "block";
        } else {
          displayNow.style.display = "none";
        }
      },
      editDiagnosis: function () {
        let incident = this.get("incident");
        let oldDiagnosis = incident.get("diagnosis").get("text");
        incident.set("diagnosis", null);
        this.set("incidentDiagnosis", oldDiagnosis);
      }
    }
  });

  _exports.default = _default;
});