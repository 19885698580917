define("managing-tool-frontend/we-manage/service-processing/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['tasksOffset'],
    tasksCurrentPage: 1,
    tasksTotalPages: 0,
    tasksOffset: 0,
    events: null,
    INisActive: true,
    TPisActive: false,
    TMisActive: false,
    THisActive: false,

    switchBreadcrumb(thatActive) {
      let that = this;
      this.set('INisActive', false);
      this.set('TPisActive', false);
      this.set('TMisActive', false);
      this.set('THisActive', false);

      switch (thatActive) {
        case 1:
          that.set("INisActive", true);
          break;

        case 2:
          that.set("TPisActive", true);
          break;

        case 3:
          that.set("TMisActive", true);
          break;

        case 4:
          that.set("THisActive", true);
          break;
      }
    },

    actions: {
      switchBreadcrumb: function (thatActive) {
        this.switchBreadcrumb(thatActive);
      },

      changePage(whatWhere) {
        let page = this.get('tasksCurrentPage');

        if (whatWhere == 1) {
          this.set('tasksCurrentPage', page + 1);
          this.set('tasksOffset', page * 6);
        } else {
          this.set('tasksCurrentPage', page - 1);
          this.set('tasksOffset', (page - 2) * 6);
        }
      },

      calendarClicked: function (event) {
        let task = this.store.findRecord("task", event.id);
        this.transitionToRoute("weManage.serviceProcessing.taskManagement.task", task);
      },
      loadOverview: function () {
        this.switchBreadcrumb(1);
        this.transitionToRoute("weManage.serviceProcessing");
      },
      processProcessing: function () {
        this.transitionToRoute("weManage.serviceProcessing.taskProcessing");
      },
      processManagement: function () {
        this.transitionToRoute("weManage.serviceProcessing.taskManagement");
      },
      processHistory: function () {
        this.transitionToRoute("weManage.serviceProcessing.taskHistory");
      },
      loadTask: function (task) {
        this.transitionToRoute("weManage.serviceProcessing.taskManagement.task", task);
      }
    }
  });

  _exports.default = _default;
});