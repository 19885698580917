define("managing-tool-frontend/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    street: _emberData.default.attr(),
    number: _emberData.default.attr(),
    zipCode: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    country: _emberData.default.attr(),
    latitude: _emberData.default.attr(),
    longitude: _emberData.default.attr(),
    customer: _emberData.default.belongsTo("customer"),
    selected: false
  });

  _exports.default = _default;
});