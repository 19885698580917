define("managing-tool-frontend/we-manage/administration/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      this.controllerFor("weManage.administration").send("switchBreadcrumb", 0);
    },

    renderTemplate() {
      this.render("navigation/administration", {
        into: "weManage",
        outlet: "navibar"
      });
      this.render("weManage/administration", {
        into: "weManage",
        outlet: "maincontent"
      });
    }

  });

  _exports.default = _default;
});