define("managing-tool-frontend/templates/navigation/service-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G1DahHPN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"OVisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"calendar-alt\"],null],false],[0,\" \"],[1,[27,\"t\",[\"overview\"],null],false],[3,\"action\",[[22,0,[]],\"loadOverview\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"IMisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"folder-open\"],null],false],[0,\" \"],[1,[27,\"t\",[\"incident.incidents\"],null],false],[3,\"action\",[[22,0,[]],\"loadIncidents\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"TMisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"tasks\"],null],false],[0,\" \"],[1,[27,\"t\",[\"task.tasks\"],null],false],[3,\"action\",[[22,0,[]],\"loadTasks\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"OUisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"users\"],null],false],[0,\" \"],[1,[27,\"t\",[\"user.users\"],null],false],[3,\"action\",[[22,0,[]],\"loadUsers\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"HisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"hourglass-end\"],null],false],[0,\" \"],[1,[27,\"t\",[\"history\"],null],false],[3,\"action\",[[22,0,[]],\"loadHistory\"]],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/templates/navigation/service-management.hbs"
    }
  });

  _exports.default = _default;
});