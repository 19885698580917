define("managing-tool-frontend/we-manage/administration/accounts/account/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      return this.store.findRecord("user", params.account_id);
    },
    afterModel: function (model) {
      this.modelFor("weManage.administration.accounts").users.forEach(function (user) {
        user.set("selected", false);
      });
      model.set("selected", true);
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set("customerRole", model.get("role"));
    }
  });

  _exports.default = _default;
});