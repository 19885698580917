define("managing-tool-frontend/helpers/incident-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.incidentState = incidentState;
  _exports.default = void 0;

  function incidentState(params) {
    let typeInt = parseInt(params);
    let state = "Undefined";

    if (typeInt === 1) {
      state = "New";
    } else if (typeInt === 2) {
      state = "Requested";
    } else if (typeInt === 3) {
      state = "Interrupted";
    } else if (typeInt === 4) {
      state = "Disclaimed";
    } else if (typeInt === 5) {
      state = "Assigned";
    } else if (typeInt === 6) {
      state = "Tech rejected";
    } else if (typeInt === 7) {
      state = "Processing Local";
    } else if (typeInt === 8) {
      state = "Processing Remote";
    } else if (typeInt === 9) {
      state = "Finished";
    } else if (typeInt === 10) {
      state = "Verification";
    } else if (typeInt === 11) {
      state = "Customer accepted";
    } else if (typeInt === 12) {
      state = "Customer rejected";
    } else if (typeInt === 13) {
      state = "Closed resolved";
    } else if (typeInt === 14) {
      state = "closed invalid";
    }

    return state;
  }

  var _default = Ember.Helper.helper(incidentState);

  _exports.default = _default;
});