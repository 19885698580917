define("managing-tool-frontend/we-manage/administration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6yhrwsJj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-6 offset-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"card-header text-white bg-primary\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"administration\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"introductionOne\"],null],false],[0,\".\"],[7,\"br\"],[9],[10],[0,\"\\n        \"],[1,[27,\"t\",[\"introductionTwo\"],null],false],[0,\".\"],[7,\"br\"],[9],[10],[0,\"\\n        \"],[1,[27,\"t\",[\"introductionTre\"],null],false],[0,\":\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"card-footer\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-outline-primary\"],[9],[1,[27,\"fa-icon\",[\"users\"],null],false],[0,\" \"],[1,[27,\"t\",[\"user.users\"],null],false],[3,\"action\",[[22,0,[]],\"loadAccounts\"]],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-outline-primary\"],[9],[1,[27,\"fa-icon\",[\"tags\"],null],false],[0,\" \"],[1,[27,\"t\",[\"customer.customers\"],null],false],[3,\"action\",[[22,0,[]],\"loadCustomers\"]],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-outline-primary\"],[9],[1,[27,\"fa-icon\",[\"folder-open\"],null],false],[0,\" \"],[1,[27,\"t\",[\"machine.types\"],null],false],[3,\"action\",[[22,0,[]],\"loadMachinetypes\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/we-manage/administration/template.hbs"
    }
  });

  _exports.default = _default;
});