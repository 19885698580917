define("managing-tool-frontend/we-manage/customer/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      incidentsOffset: {
        refreshModel: true
      }
    },

    activate() {
      this.controllerFor("weManage.customer").send("switchBreadcrumb", 1);
    },

    renderTemplate: function () {
      this.render("navigation/customer", {
        into: "weManage",
        outlet: "navibar"
      });
      this.render("weManage/customer", {
        into: "weManage",
        outlet: "maincontent"
      });
    },
    model: function (params) {
      return this.store.query("incident", {
        states: '1!2!5!6!7!8!9!10!11!12',
        limit: 6,
        offset: params.incidentsOffset
      }).then(incidents => {
        return {
          incidents: incidents,
          incidentsM: incidents.get("meta")
        };
      });
    },
    afterModel: function (model) {
      model.incidents.forEach(function (incident) {
        incident.set("selected", false);
      });
    },

    setupController(controller, {
      incidents,
      incidentsM
    }) {
      this._super(controller, {
        incidents,
        incidentsM
      });

      controller.set("incidentsTotalPages", Math.ceil(incidentsM.count / 6));
    },

    redirect: function ({
      incidents
    }, transition) {
      if (transition.targetName === this.routeName + ".index") {
        if (incidents != null) {
          if (incidents.get("length") > 0) {
            this.transitionTo("weManage.customer.incidentDetail", incidents.get("firstObject"));
          }
        }
      }
    },
    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});