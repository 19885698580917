define("managing-tool-frontend/we-manage/administration/customers/customer/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate() {
      this.render("weManage/administration/customers/customer/edit", {
        into: "weManage/administration/customers",
        outlet: "customerDetails"
      });
    },

    model() {
      return this.modelFor("weManage.administration.customers.customer");
    }

  });

  _exports.default = _default;
});