define("managing-tool-frontend/components/incidentdetail-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleIncidentDetailPanel: function () {
        let displayNow = document.getElementById("IncidentDetail");

        if (displayNow.style.display === "none") {
          displayNow.style.display = "table";
        } else {
          displayNow.style.display = "none";
        }
      }
    }
  });

  _exports.default = _default;
});