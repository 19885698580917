define("managing-tool-frontend/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "jquery"], function (_exports, _applicationRouteMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    intl: Ember.inject.service(),

    beforeModel() {
      this.intl.setLocale(['en-us', 'de-de', 'es-es']);
    },

    activate() {
      (0, _jquery.default)(function () {
        (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();
      });
    },

    deactivate() {
      (0, _jquery.default)('[data-toggle="tooltip"]').tooltip('hide');
    }

  });

  _exports.default = _default;
});