define("managing-tool-frontend/we-manage/administration/accounts/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      usersOffset: {
        refreshModel: true
      }
    },

    activate() {
      this.controllerFor("weManage.administration").send("switchBreadcrumb", 1);
    },

    model(params) {
      return this.store.query("user", {
        limit: 18,
        offset: params.usersOffset
      }).then(users => {
        return {
          users: users,
          usersM: users.get("meta")
        };
      });
    },

    afterModel({
      users
    }) {
      users.forEach(function (user) {
        user.set("selected", false);
      });
    },

    setupController(controller, {
      users,
      usersM
    }) {
      this._super(controller, {
        users,
        usersM
      });

      controller.set("usersTotalPages", Math.ceil(usersM.count / 18));
    },

    renderTemplate() {
      this.render("weManage/administration/accounts", {
        into: "weManage",
        outlet: "maincontent"
      });
    },

    actions: {
      loadAccount: function (user) {
        return this.transitionTo("weManage.administration.accounts.account", user);
      },
      newUser: function () {
        return this.transitionTo("weManage.administration.accounts.new");
      },

      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});