define("managing-tool-frontend/instance-initializers/session-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    const applicationRoute = instance.lookup("route:application");
    const session = instance.lookup("service:session");
    session.on("authenticationSucceeded", function () {
      applicationRoute.transitionTo("weManage");
    });
    session.on("invalidationSucceeded", function () {
      applicationRoute.transitionTo("login");
    });
  }

  var _default = {
    initialize,
    name: "session-events",
    after: "ember-simple-auth"
  };
  _exports.default = _default;
});