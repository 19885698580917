define("managing-tool-frontend/adapters/application", ["exports", "ember-data", "managing-tool-frontend/app", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _app, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    namespace: 'api',
    host: _app.default.API_HOST,

    authorize(xhr) {
      let {
        access_token
      } = this.get('session.data.authenticated');

      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
      }
    }

  });

  _exports.default = _default;
});