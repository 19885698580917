define("managing-tool-frontend/we-manage/administration/customers/customer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6mdz7+Qo",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[\"customerinfo-panel\"],[[\"customer\",\"edit\",\"deleteCustomer\",\"editCustomer\",\"showMachines\",\"showLocations\"],[[23,[\"model\"]],true,[27,\"action\",[[22,0,[]],\"deleteCustomer\"],null],[27,\"action\",[[22,0,[]],\"editCustomer\"],null],[27,\"action\",[[22,0,[]],\"showMachines\"],null],[27,\"action\",[[22,0,[]],\"showLocations\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/we-manage/administration/customers/customer/template.hbs"
    }
  });

  _exports.default = _default;
});