define("managing-tool-frontend/we-manage/administration/customers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["customersOffset"],
    customersCurrentPage: 1,
    customersTotalPages: 0,
    customersOffset: 0,
    actions: {
      changePage(whatWhere) {
        let page = this.get("customersCurrentPage");

        if (whatWhere == 1) {
          this.set("customersCurrentPage", page + 1);
          this.set("customersOffset", page * 6);
        } else {
          this.set("customersCurrentPage", page - 1);
          this.set("customersOffset", (page - 2) * 6);
        }
      },

      reloadModel() {
        this.send("refreshModel");
      }

    }
  });

  _exports.default = _default;
});