define("managing-tool-frontend/we-manage/service-management/incident-management/new/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    incidentController: Ember.inject.controller('weManage.serviceManagement.incidentManagement'),

    init() {
      this._super(...arguments);

      this.allPriority = ["NORMAL", "HIGH", "CRITICAL"];
    },

    selPriority: null,
    selMachine: null,
    selCustomer: null,
    dateTimeNow: Ember.computed('', function () {
      return (0, _moment.default)().format();
    }),
    allMachines: Ember.computed("selCustomer", function () {
      let allFilteredMachines = [];
      let selectedCustomer = null;
      let selCustomer = this.get("selCustomer");

      if (selCustomer === null) {
        this.get("allCustomers").then(function (thisCustomer) {
          selectedCustomer = thisCustomer.get("firstObject");
        });
      } else {
        selectedCustomer = selCustomer;
      }

      this.store.findAll("machine").then(function (allMachines) {
        allMachines.forEach(function (machine) {
          machine.get("customer").then(function (machineCustomer) {
            if (selectedCustomer === machineCustomer) {
              allFilteredMachines.pushObject(machine);
            }
          });
        });
      });
      return allFilteredMachines;
    }),
    allCustomers: Ember.computed("customer", function () {
      return this.store.query("customer", {
        limit: 18,
        offset: 0
      });
    }),
    actions: {
      createButton: function (state) {
        let jsonAppointment = new Date().toJSON();
        let selPriority = this.get("selPriority");

        if (selPriority === "HIGH") {
          this.set("selPriority", 2);
        } else if (selPriority === "CRITICAL") {
          this.set("selPriority", 3);
        } else {
          this.set("selPriority", 1);
        }

        let chosenDate = this.get("chosenDate");

        if (chosenDate) {
          jsonAppointment = chosenDate.toJSON();
        }

        let selMachine = this.get("selMachine");

        if (selMachine === null) {
          selMachine = this.get("allMachines").get("firstObject");
        }

        let newIncident = this.store.createRecord("incident", {
          subject: this.get("subject"),
          description: this.get("description"),
          state: state,
          priority: this.get("selPriority"),
          appointment: jsonAppointment,
          machine: selMachine
        });
        newIncident.save().then(function () {
          this.set("subject", "");
          this.set("description", "");
          this.get("incidentController").send("reloadModel");
          return this.transitionToRoute("weManage.serviceManagement.incidentManagement");
        });
      },
      abortButton: function () {
        this.set("subject", "");
        this.set("description", "");
        return this.transitionToRoute("weManage.serviceManagement.incidentManagement");
      }
    }
  });

  _exports.default = _default;
});