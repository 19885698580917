define("managing-tool-frontend/templates/navigation/service-processing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mk924Axo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"INisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"calendar-alt\"],null],false],[0,\" \"],[1,[27,\"t\",[\"overview\"],null],false],[3,\"action\",[[22,0,[]],\"loadOverview\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"TPisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"wrench\"],null],false],[0,\" \"],[1,[27,\"t\",[\"task.current\"],null],false],[3,\"action\",[[22,0,[]],\"processProcessing\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"TMisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"tasks\"],null],false],[0,\" \"],[1,[27,\"t\",[\"task.management\"],null],false],[3,\"action\",[[22,0,[]],\"processManagement\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"THisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"hourglass-end\"],null],false],[0,\" \"],[1,[27,\"t\",[\"history\"],null],false],[3,\"action\",[[22,0,[]],\"processHistory\"]],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/templates/navigation/service-processing.hbs"
    }
  });

  _exports.default = _default;
});