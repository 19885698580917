define("managing-tool-frontend/models/task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    subject: _emberData.default.attr(),
    description: _emberData.default.attr(),
    state: _emberData.default.attr(),
    priority: _emberData.default.attr(),
    created: _emberData.default.attr(),
    appointment: _emberData.default.attr(),
    creator: _emberData.default.belongsTo("user"),
    assignedUser: _emberData.default.belongsTo("user"),
    incident: _emberData.default.belongsTo("incident"),
    taskLogs: _emberData.default.hasMany("taskLog", {
      async: true
    }),
    selected: false,
    fDate: Ember.computed("created", function () {
      let reDate = new Date(this.get("created"));
      return reDate.toLocaleDateString("de-DE", {
        month: "2-digit",
        day: "2-digit"
      });
    }),
    fTime: Ember.computed("created", function () {
      let reDate = new Date(this.get("created"));
      return reDate.toLocaleTimeString();
    }),
    fDDate: Ember.computed("appointment", function () {
      let reDate = new Date(this.get("appointment"));
      return reDate.toLocaleDateString("de-DE", {
        month: "2-digit",
        day: "2-digit"
      });
    }),
    fDTime: Ember.computed("appointment", function () {
      let reDate = new Date(this.get("appointment"));
      return reDate.toLocaleTimeString();
    }),
    priorityColor: Ember.computed("priority", function () {
      let usedColor;
      let priority = this.get("priority");

      if (priority === 2) {
        usedColor = "warning";
      } else if (priority === 3) {
        usedColor = "danger";
      }

      return usedColor;
    })
  });

  _exports.default = _default;
});