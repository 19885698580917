define("managing-tool-frontend/we-manage/service-processing/task-management/task/conclusion/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      (0, _jquery.default)(function () {
        (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();
      });
    },

    renderTemplate: function () {
      this.render("weManage/serviceProcessing/taskManagement/task/conclusion", {
        into: "weManage",
        outlet: "maincontent"
      });
    },
    model: function () {
      return this.modelFor("weManage.serviceProcessing.taskManagement.task");
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set("task", model);
      model.get("incident").then(function (incident) {
        controller.set("incident", incident);
        incident.get("machine").then(function (machine) {
          controller.set("machine", machine);
          machine.get("customer").then(function (customer) {
            controller.set("customer", customer);
          });
        });
      });
    },

    deactivate() {
      (0, _jquery.default)('[data-toggle="tooltip"]').tooltip('hide');
    }

  });

  _exports.default = _default;
});