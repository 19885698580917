define("managing-tool-frontend/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    firstname: _emberData.default.attr(),
    lastname: _emberData.default.attr(),
    email: _emberData.default.attr(),
    roles: _emberData.default.attr(),
    defaultRole: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    latitude: _emberData.default.attr(),
    longitude: _emberData.default.attr(),
    lastLogin: _emberData.default.attr(),
    customer: _emberData.default.belongsTo("customer"),
    // -> for service management - technician overview
    links: _emberData.default.attr(),
    inProgressId: _emberData.default.attr(),
    inProgressSubject: _emberData.default.attr(),
    allTasks: _emberData.default.attr(),
    unseenTasks: _emberData.default.attr(),
    // <- for service management - technician overview
    selected: false,
    name: Ember.computed("firstname", "lastname", function () {
      // connect first- and lastname
      let first = this.get("firstname");
      let last = this.get("lastname");
      return first + " " + last;
    }),
    fullName: Ember.computed("firstname", "lastname", function () {
      return `${this.get("firstname")} ${this.get("lastname")}`;
    }),
    fRole: Ember.computed('intl.locale', 'roles', function () {
      let roleList = [];
      let role = this.get("roles");

      if ((role & 1) !== 0) {
        roleList.addObject(this.intl.t('admin'));
      }

      if ((role & 2) !== 0) {
        roleList.addObject(this.intl.t('manager'));
      }

      if ((role & 4) !== 0) {
        roleList.addObject(this.intl.t('rTechnician'));
      }

      if ((role & 8) !== 0) {
        roleList.addObject(this.intl.t('lTechnician'));
      }

      if ((role & 16) !== 0) {
        roleList.addObject(this.intl.t('customer.customer'));
      }

      return roleList;
    }),
    location: Ember.computed("latitude", "longitude", function () {
      let location = [];
      let lat = this.get("latitude");
      let lng = this.get("longitude");
      location.addObject(lat);
      location.addObject(lng);
      return location;
    })
  });

  _exports.default = _default;
});