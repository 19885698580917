define("managing-tool-frontend/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    email: null,
    password: null,
    errors: null,
    actions: {
      login: function () {
        let that = this;
        let {
          email,
          password
        } = this.getProperties("email", "password");
        this.set("email", null);
        this.set("password", null);
        this.get("session").authenticate("authenticator:oauth2", email, password).catch(reason => {
          that.set("errors", reason.error || reason);
        });
      }
    }
  });

  _exports.default = _default;
});