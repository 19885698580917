define("managing-tool-frontend/we-manage/administration/customers/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      customersOffset: {
        refreshModel: true
      }
    },

    activate() {
      this.controllerFor("weManage.administration").send("switchBreadcrumb", 2);
    },

    model(params) {
      return this.store.query("customer", {
        limit: 18,
        offset: params.customersOffset
      }).then(customers => {
        return {
          customers: customers,
          customersM: customers.get("meta")
        };
      });
    },

    afterModel({
      customers
    }) {
      if (customers) {
        customers.forEach(function (customer) {
          customer.set("selected", false);
        });
      }
    },

    setupController(controller, {
      customers,
      customersM
    }) {
      this._super(controller, {
        customers,
        customersM
      });

      controller.set("customersTotalPages", Math.ceil(customersM.count / 18));
    },

    renderTemplate() {
      this.render("weManage/administration/customers", {
        into: "weManage",
        outlet: "maincontent"
      });
    },

    actions: {
      loadCustomer: function (customer) {
        if (customer.name) {
          return this.transitionTo("weManage.administration.customers.customer", customer);
        }
      },
      newCustomer: function () {
        return this.transitionTo("weManage.administration.customers.new");
      },

      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});