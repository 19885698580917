define("managing-tool-frontend/we-manage/service-management/controller", ["exports", "managing-tool-frontend/app", "jquery", "chartjs"], function (_exports, _app, _jquery, _chartjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    events: null,
    queryParams: ['incidentsOffset', 'tasksOffset'],
    incidentsCurrentPage: 1,
    incidentsTotalPages: 0,
    incidentsOffset: 0,
    tasksCurrentPage: 1,
    tasksTotalPages: 0,
    tasksOffset: 0,
    uneditedIncidents: 0,
    incidentsInProgress: 0,
    verifyIncidents: 0,
    rejectedIncidents: 0,
    OVisActive: true,
    IMisActive: false,
    TMisActive: false,
    OUisActive: false,
    HisActive: false,

    switchBreadcrumb(thatActive) {
      let that = this;
      this.set('OVisActive', false);
      this.set('IMisActive', false);
      this.set('TMisActive', false);
      this.set('OUisActive', false);
      this.set('HisActive', false);

      switch (thatActive) {
        case 1:
          that.set("OVisActive", true);
          break;

        case 2:
          that.set("IMisActive", true);
          break;

        case 3:
          that.set("TMisActive", true);
          break;

        case 4:
          that.set("OUisActive", true);
          break;

        case 5:
          that.set("HisActive", true);
          break;
      }
    },

    init() {
      this._super(...arguments);

      let that = this;

      if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
        Ember.run.later(function () {
          that.colorCalendar();
        }, 1500);
      } else {
        window.addEventListener("DOMContentLoaded", function () {
          Ember.run.later(function () {
            that.colorCalendar();
          }, 1500);
        });
      }
    },

    colorCalendar() {
      let technicianCount;
      this.store.query("user", {
        limit: 4,
        offset: 0
      }).then(users => {
        technicianCount = users.get("meta").count;
      });
      let allNeededDays = [];
      let today = document.getElementsByClassName("fc-today");

      for (let j = 0; j < today.length; j++) {
        let boxj = today[j];
        let boxjClassList = boxj.classList;
        let testj = boxjClassList.contains("fc-widget-content");

        if (testj) {
          allNeededDays.pushObject(boxj);
        }
      }

      let allFutures = document.getElementsByClassName("fc-future");

      for (let i = 0; i < allFutures.length; i++) {
        let boxi = allFutures[i];
        let boxiClassList = boxi.classList;
        let testi = boxiClassList.contains("fc-widget-content");

        if (testi) {
          allNeededDays.pushObject(boxi);
        }
      }

      let allNeededDaysBak = allNeededDays;
      let allTaskDays = [];
      let allTaskDaysTmp = [];
      let model = this.get("model.tasks");

      if (model) {
        model.forEach(function (task) {
          let taskDate = task.get("appointment");
          let reDate = new Date(taskDate);
          let taskDateTime = reDate.getTime();
          allTaskDaysTmp.push(taskDateTime);
        });
        allTaskDaysTmp.sort(function (sa, sb) {
          return sa - sb;
        });
        allTaskDaysTmp.forEach(function (taskDay) {
          let newBox = new Date(taskDay);
          let Box = newBox.toJSON();
          let lastBox = Box.slice(0, 10);
          allTaskDays.push(lastBox);
        });
        let current = null;
        let counted = 0;

        for (let m = 0; m < allTaskDays.length; m++) {
          if (allTaskDays[m] !== current) {
            if (counted > 0) {
              for (let l = 0; l < allNeededDays.length; l++) {
                let thisDay = allNeededDays[l];
                let thisDataset = thisDay.dataset;
                let thisDate = thisDataset.date;

                if (thisDate === current) {
                  let gravity = counted / technicianCount;

                  if (gravity < 0.1) {
                    thisDay.className = thisDay.className + " allmostClear";
                  } else if (gravity < 0.5) {
                    thisDay.className = thisDay.className + " ordinaryTime";
                  } else if (gravity < 0.9) {
                    thisDay.className = thisDay.className + " allmostFull";
                  } else {
                    thisDay.className = thisDay.className + " completelyFull";
                  }

                  let lIndex = allNeededDaysBak.indexOf(thisDay);

                  if (lIndex > -1) {
                    allNeededDaysBak.splice(lIndex, 1);
                  }
                }
              }
            }

            current = allTaskDays[m];
            counted = 1;
          } else {
            counted++;
          }
        }

        if (counted > 0) {
          for (let k = 0; k < allNeededDays.length; k++) {
            let thisKDay = allNeededDays[k];
            let thisKDataset = thisKDay.dataset;
            let thisKDate = thisKDataset.date;

            if (thisKDate === current) {
              let gravityK = counted / technicianCount;

              if (gravityK < 0.1) {
                thisKDay.className = thisKDay.className + " allmostClear";
              } else if (gravityK < 0.5) {
                thisKDay.className = thisKDay.className + " ordinaryTime";
              } else if (gravityK < 0.9) {
                thisKDay.className = thisKDay.className + " allmostFull";
              } else {
                thisKDay.className = thisKDay.className + " completelyFull";
              }

              let kIndex = allNeededDaysBak.indexOf(thisKDay);

              if (kIndex > -1) {
                allNeededDaysBak.splice(kIndex, 1);
              }
            }
          }
        }

        for (let n = 0; n < allNeededDaysBak.length; n++) {
          let thisNDay = allNeededDaysBak[n];
          thisNDay.className = thisNDay.className + " allmostClear";
        }
      }
    },

    generateCharts() {
      let that = this;
      let chartOptions = {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false
            }
          }]
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        elements: {
          point: {
            radius: 0
          }
        }
      };
      let firstChart = new _chartjs.default(document.getElementById("firstChart").getContext("2d"), {
        type: "line",
        data: {
          labels: [],
          datasets: [{
            label: "Dataset",
            fillColor: "rgba(255,255,255,0.3)",
            strokeColor: "rgba(255,255,255,1)",
            data: []
          }]
        },
        options: chartOptions
      });
      let secondChart = new _chartjs.default(document.getElementById("secondChart").getContext("2d"), {
        type: "line",
        data: {
          labels: [],
          datasets: [{
            label: "Dataset",
            fillColor: "rgba(255,255,255,0.3)",
            strokeColor: "rgba(255,255,255,1)",
            data: []
          }]
        },
        options: chartOptions
      });
      let thirdChart = new _chartjs.default(document.getElementById("thirdChart").getContext("2d"), {
        type: "line",
        data: {
          labels: [],
          datasets: [{
            label: "Dataset",
            fillColor: "rgba(255,255,255,0.3)",
            strokeColor: "rgba(255,255,255,1)",
            data: []
          }]
        },
        options: chartOptions
      });
      let fourthChart = new _chartjs.default(document.getElementById("fourthChart").getContext("2d"), {
        type: "line",
        data: {
          labels: [],
          datasets: [{
            label: "Dataset",
            fillColor: "rgba(255,255,255,0.3)",
            strokeColor: "rgba(255,255,255,1)",
            data: []
          }]
        },
        options: chartOptions
      });
      let lsat = JSON.parse(localStorage.getItem("ember_simple_auth-session"))["authenticated"]["access_token"];

      _jquery.default.ajax({
        type: "GET",
        url: _app.default.API_HOST + "/api/incidentstats",
        data: "",
        processData: false,
        contentType: false,
        headers: {
          Authorization: "Bearer " + lsat
        },
        success: function (data) {
          if (typeof data.error === "undefined") {
            let thatForCount = 0,
                oldId = 0;

            for (let i = 0; i < data.length; i++) {
              let uneditedIncidentsRawData = data[i].new + data[i].requested + data[i].confirmed + data[i].techRejected;
              let incidentsInProgressRawData = data[i].assigned + data[i].processingLocal + data[i].processingRemote;
              let verifyIncidentsRawData = data[i].finished + data[i].verification;
              let rejectedIncidentsRawData = data[i].custRejected;
              let allWorkingIncidents = uneditedIncidentsRawData + incidentsInProgressRawData + verifyIncidentsRawData + rejectedIncidentsRawData;
              let percentPerIncidentData = 100 / allWorkingIncidents;

              if (data[i].id > oldId) {
                oldId = data[i].id;
                thatForCount = i;
              }

              firstChart.data.labels.push(data[i].created);
              firstChart.data.datasets[0].data.push(Math.round(uneditedIncidentsRawData * percentPerIncidentData));
              secondChart.data.labels.push(data[i].created);
              secondChart.data.datasets[0].data.push(Math.round(incidentsInProgressRawData * percentPerIncidentData));
              thirdChart.data.labels.push(data[i].created);
              thirdChart.data.datasets[0].data.push(Math.round(verifyIncidentsRawData * percentPerIncidentData));
              fourthChart.data.labels.push(data[i].created);
              fourthChart.data.datasets[0].data.push(Math.round(rejectedIncidentsRawData * percentPerIncidentData));
            }

            that.set("uneditedIncidents", firstChart.data.datasets[0].data[thatForCount]);
            that.set("incidentsInProgress", secondChart.data.datasets[0].data[thatForCount]);
            that.set("verifyIncidents", thirdChart.data.datasets[0].data[thatForCount]);
            that.set("rejectedIncidents", fourthChart.data.datasets[0].data[thatForCount]);
            firstChart.update();
            secondChart.update();
            thirdChart.update();
            fourthChart.update();
          } else {
            console.error("ajax success with error 1: ", data);
          }
        },
        error: function (jqXHR, textStatus) {
          console.error("ajax error 2: ", textStatus);
        }
      });

      (0, _jquery.default)("#firstChart").height("100px");
      (0, _jquery.default)("#secondChart").height("100px");
      (0, _jquery.default)("#thirdChart").height("100px");
      (0, _jquery.default)("#fourthChart").height("100px");
    },

    actions: {
      generateChartsAction: function () {
        if (document.getElementById("firstChart")) {
          this.generateCharts();
        } else {
          let that = this;
          Ember.run.later(function () {
            if (document.getElementById("firstChart")) {
              that.generateCharts();
            } else {
              Ember.run.later(function () {
                if (document.getElementById("firstChart")) {
                  that.generateCharts();
                } else {
                  Ember.run.later(function () {
                    if (document.getElementById("firstChart")) {
                      that.generateCharts();
                    }
                  }, 500);
                }
              }, 500);
            }
          }, 500);
        }
      },
      calendarClicked: function (event) {
        let id = event.id;
        let clickedCase = event.className.get("firstObject");

        if (clickedCase === "calendarTask") {
          let task = this.store.findRecord("task", id);
          this.transitionToRoute("weManage.serviceManagement.taskManagement.task", task);
        } else if (clickedCase === "calendarIncident") {
          let incident = this.store.findRecord("incident", id);
          this.transitionToRoute("weManage.serviceManagement.incidentManagement.incident", incident);
        } else {
          console.error("Error");
        }
      },
      calendarDayClicked: function (date, jsEvent, view) {
        console.log("date: ", date);
        console.log("jsEvent: ", jsEvent);
        console.log("view: ", view);
      },
      calendarEventClicked: function (event) {
        let eventType = event.eventType;
        let eventId = event.id;

        if (eventType === "task") {
          this.transitionToRoute("weManage.serviceManagement.taskManagement.task", eventId);
        } else if (eventType === "incident") {
          this.transitionToRoute("weManage.serviceManagement.incidentManagement.incident", eventId);
        } else {
          console.error("eventType ERROR!");
        }
      },
      switchBreadcrumb: function (thatActive) {
        this.switchBreadcrumb(thatActive);
      },
      loadOverview: function () {
        this.switchBreadcrumb(1);
        this.transitionToRoute("weManage.serviceManagement");
      },
      loadIncidents: function () {
        this.transitionToRoute("weManage.serviceManagement.incidentManagement");
      },
      loadIncident: function (incident) {
        this.transitionToRoute("weManage.serviceManagement.incidentManagement.incident", incident);
      },
      loadTasks: function () {
        this.transitionToRoute("weManage.serviceManagement.taskManagement");
      },
      loadTask: function (task) {
        this.transitionToRoute("weManage.serviceManagement.taskManagement.task", task);
      },
      loadUsers: function () {
        this.transitionToRoute("weManage.serviceManagement.overviewUser");
      },
      loadHistory: function () {
        this.transitionToRoute("weManage.serviceManagement.history");
      },

      changePage(whatWhere) {
        if (whatWhere < 2) {
          let page = this.get('incidentsCurrentPage');

          if (whatWhere == 1) {
            this.set('incidentsCurrentPage', page + 1);
            this.set('incidentsOffset', page * 6);
          } else {
            this.set('incidentsCurrentPage', page - 1);
            this.set('incidentsOffset', (page - 2) * 6);
          }
        } else {
          let page = this.get('tasksCurrentPage');

          if (whatWhere == 3) {
            this.set('tasksCurrentPage', page + 1);
            this.set('tasksOffset', page * 6);
          } else {
            this.set('tasksCurrentPage', page - 1);
            this.set('tasksOffset', (page - 2) * 6);
          }
        }
      }

    }
  });

  _exports.default = _default;
});