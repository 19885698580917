define("managing-tool-frontend/we-manage/service-processing/task-management/task/conclusion/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      cancelButton: function () {
        this.transitionToRoute("weManage.serviceProcessing.taskManagement");
      },
      saveButton: function (finishVersion) {
        let that = this;
        let task = this.get("model");
        task.get("incident").then(function (incident) {
          let newComment = that.store.createRecord("incidentComment", {
            text: that.get("finalReport"),
            type: 4,
            incident: incident
          });
          let newIncidentState = 9;

          if (finishVersion) {
            newIncidentState = 11;
          }

          incident.get("incidentComments").pushObject(newComment);
          incident.set("state", newIncidentState);
          newComment.save().then(function () {
            incident.save().then(function (savedIncident) {
              savedIncident.hasMany("incidentLogs").reload();
              task.set("state", 7);
              task.save().then(function (savedTask) {
                savedTask.hasMany("taskLogs").reload();
              });
            });
          });
          that.set("finalReport", "");
        });
        Ember.run.later(function () {
          that.transitionToRoute("weManage.serviceProcessing.taskManagement");
        }, 1500);
      }
    }
  });

  _exports.default = _default;
});