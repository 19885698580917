define("managing-tool-frontend/we-manage/service-management/incident-management/incident/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    serviceManagement: Ember.inject.controller('weManage.serviceManagement'),
    commentsShown: true,
    isTaskable: Ember.computed("state", function () {
      let state = this.get("state");
      let cantDo = false;

      if (state === 11 || state === 13 || state === 14) {
        cantDo = true;
      }

      return cantDo;
    }),
    isVerifyable: Ember.computed("state", function () {
      return this.get("state") !== 9;
    }),
    isCloseable: Ember.computed("state", function () {
      let state = this.get("state");
      let cantDo = true;

      if (state === 3 || state === 11) {
        cantDo = false;
      }

      return cantDo;
    }),
    actions: {
      createTaskButton: function () {
        (0, _jquery.default)('#taskCreateButton').tooltip('hide');
        return this.transitionToRoute("weManage.serviceManagement.taskManagement.new");
      },
      verifyButton: function () {
        (0, _jquery.default)('#incidentVerifyButton').tooltip('hide');
        let incident = this.get("model");
        this.set("state", 10);
        incident.set("state", 10);
        incident.save().then(function (savedIncident) {
          savedIncident.hasMany("incidentLogs").reload();
        });
      },
      closeButton: function () {
        (0, _jquery.default)('#incidentCloseButton').tooltip('hide');
        let incident = this.get("model");
        this.set("state", 13);
        incident.set("state", 13);
        incident.save().then(function (savedIncident) {
          savedIncident.hasMany("incidentLogs").reload();
        });
      },
      addCommentButton: function () {
        let type = 1;
        let commentRadios = document.getElementsByName("commentRadios");

        for (let i = 0, length = commentRadios.length; i < length; i++) {
          if (commentRadios[i].checked) {
            type = commentRadios[i].value;
            break;
          }
        }

        let newComment = this.store.createRecord("incidentComment", {
          text: this.get("commentAdded"),
          type: type,
          incident: this.get("model")
        });
        newComment.save();
        this.set("commentAdded", "");
      },
      toggleCommentsShow: function () {
        let commentsShown = this.get('commentsShown');
        this.set('commentsShown', !commentsShown);
      },
      gotoThisTask: function (thatTask) {
        return this.transitionToRoute("weManage.serviceManagement.taskManagement.task", thatTask, {
          queryParams: {
            tasksTasksOffset: 0
          }
        });
      }
    }
  });

  _exports.default = _default;
});