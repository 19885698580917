define("managing-tool-frontend/we-manage/customer/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['incidentsOffset'],
    incidentsCurrentPage: 1,
    incidentsTotalPages: 0,
    incidentsOffset: 0,
    hasMachine: Ember.computed("machine", function () {
      return this.store.findAll("machine");
    }),
    OVActive: false,
    CRActive: false,
    HIActive: false,

    switchBreadcrumb(thatActive) {
      let that = this;
      this.set('OVActive', false);
      this.set('CRActive', false);
      this.set('HIActive', false);

      switch (thatActive) {
        case 1:
          that.set("OVActive", true);
          break;

        case 2:
          that.set("CRActive", true);
          break;

        case 3:
          that.set("HIActive", true);
          break;
      }
    },

    actions: {
      switchBreadcrumb: function (thatActive) {
        this.switchBreadcrumb(thatActive);
      },
      loadOverview: function () {
        this.switchBreadcrumb(1);
        this.transitionToRoute("weManage.customer");
      },
      loadCreate: function () {
        this.transitionToRoute("weManage.customer.incidentCreate");
      },
      loadHistory: function () {
        this.transitionToRoute("weManage.customer.history");
      },
      loadIncident: function (incident) {
        this.transitionToRoute("weManage.customer.incidentDetail", incident);
      },

      changePage(whatWhere) {
        let page = this.get('incidentsCurrentPage');

        if (whatWhere == 1) {
          this.set('incidentsCurrentPage', page + 1);
          this.set('incidentsOffset', page * 6);
        } else {
          this.set('incidentsCurrentPage', page - 1);
          this.set('incidentsOffset', (page - 2) * 6);
        }
      },

      reloadModel() {
        this.send("refreshModel");
      }

    }
  });

  _exports.default = _default;
});