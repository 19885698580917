define("managing-tool-frontend/templates/machinedetails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w16j078W",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[\"machinedetail-panel\"],[[\"machine\",\"machineType\"],[[23,[\"machine\"]],[23,[\"machineType\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/templates/machinedetails.hbs"
    }
  });

  _exports.default = _default;
});