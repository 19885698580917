define("managing-tool-frontend/we-manage/administration/customers/customer/locations/location/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      saveAndGoBack: function () {
        let location = this.get("model");
        location.save();
        return this.transitionToRoute("weManage.administration.customers.customer.locations.location", location);
      },
      abortAndGoBack: function () {
        let location = this.get("model");
        location.rollbackAttributes();
        return this.transitionToRoute("weManage.administration.customers.customer.locations.location", location);
      }
    }
  });

  _exports.default = _default;
});