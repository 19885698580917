define("managing-tool-frontend/we-manage/administration/customers/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    customersController: Ember.inject.controller('weManage.administration.customers'),
    name: null,
    actions: {
      saveAndGoBack: function () {
        let newCustomer = this.store.createRecord("customer", {
          name: this.get("name"),
          street: this.get("street"),
          number: this.get("number"),
          zipCode: this.get("zipCode"),
          city: this.get("city"),
          state: this.get("state"),
          country: this.get("country")
        });
        newCustomer.save();
        this.set("name", "");
        this.set("street", "");
        this.set("number", "");
        this.set("zipCode", "");
        this.set("city", "");
        this.set("state", "");
        this.set("country", "");
        this.get("customersController").send("reloadModel");
        return this.transitionToRoute("weManage.administration.customers");
      },
      abortButton: function () {
        this.set("name", "");
        this.set("street", "");
        this.set("number", "");
        this.set("zipCode", "");
        this.set("city", "");
        this.set("state", "");
        this.set("country", "");
        return this.transitionToRoute("weManage.administration.customers");
      }
    }
  });

  _exports.default = _default;
});