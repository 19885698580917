define("managing-tool-frontend/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "abort": "abort",
    "addComment": "add Comment",
    "admin": "Admin",
    "administration": "Administration",
    "annotate": "annotate",
    "answer": "Answer",
    "appointment": "Appointment",
    "assignedTechnician": "Assigned Technician",
    "back": "Back",
    "cancel": "cancel",
    "categoryName": "Category Name",
    "changeRole": "Change your Role",
    "changeToRole": "To change your active Role in this system, select one of your assigned roles",
    "chat": "Chat",
    "chooseHistoryArea": "Please, choose your desired history area",
    "city": "City",
    "close": "close",
    "commentExplanation": "Write your notes you want to comment",
    "comments": "Comments",
    "companyName": "Company Name",
    "country": "Country",
    "create": "create",
    "created": "Created",
    "creator": "Creator",
    "creatorCreated": "Creator/created",
    "customer": {
      "customer": "Customer",
      "customers": "Customers",
      "delete": "delete Customer",
      "deleteQuestion": "Do you really want to delete the Customer named",
      "edit": "Customer Edit",
      "info": "Customer Info",
      "locations": "Customer Locations",
      "machines": "Customer Machines",
      "new": "new Customer"
    },
    "date": "Date",
    "defaultRole": "default Role",
    "delete": "delete",
    "description": "Description",
    "diagnosis": "Diagnosis",
    "edit": "Edit",
    "email": "Email",
    "finalReport": "Final report",
    "firstname": "Firstname",
    "hide": "hide",
    "history": "History",
    "historyIntroductionOne": "Welcome to the Service Management-History-Part of this App",
    "historyIntroductionTwo": "In this section you can take a look to all incidents and task you ever adopted, coordinated, rejected or closed",
    "id": "ID",
    "in": "in",
    "inProgress": "in Progress",
    "incident": {
      "abortCreate": "abort create Incident",
      "abortCreateIncident": {
        "explanation": "abort the incident creation process",
        "title": "abort create Incident"
      },
      "accept": {
        "explanation": "accept this incident is completly finished",
        "title": "Accept"
      },
      "active": "active Incidents",
      "askCreateIncident": {
        "explanation": "request the customer to create a incident",
        "title": "ask create Incident"
      },
      "close": {
        "explanation": "close incident as fully completed",
        "title": "Close Incident"
      },
      "confirm": {
        "explanation": "confirming this incident currently exists",
        "title": "Confirm"
      },
      "confirmCreate": "confirm create Incident",
      "confirmCreateIncident": {
        "explanation": "definitely create a incident",
        "title": "confirm create Incident"
      },
      "create": "create Incident",
      "detail": "Incident Detail",
      "history": "History Incidents",
      "inProgress": "Incidents in progress",
      "incident": "Incident",
      "incidents": "Incidents",
      "info": "Incident Info",
      "interrupt": {
        "explanation": "inquiry to interrupt this incident",
        "title": "Interrupt"
      },
      "invalid": {
        "explanation": "reject this incident",
        "title": "Invalid"
      },
      "management": "Incident Management",
      "noActive": "No active Incidents",
      "reject": {
        "explanation": "refuse this incident is NOT finished",
        "title": "Reject"
      },
      "rejected": "Rejected Incidents",
      "selected": "selected Incident",
      "source": "Source Incident",
      "switchTo": "switch to Incident",
      "unedited": "Unedited Incidents",
      "verify": {
        "explanation": "ask customer to verificate this incident is fully completed",
        "title": "Verify Incident"
      },
      "verifys": "Verify Incidents"
    },
    "intro": {
      "admin": {
        "description": "Der Administrator hat die Möglichkeit übergeordnete Daten, wie zum Beispiel allgemeine Kundeninformationen, in die Applikation einzupflegen und zu verwalten. Der Administrator ist in der Regel ein Mitarbeiter des Anlagenanbieters.",
        "title": "Administrator"
      },
      "contact": "Contact",
      "demoAccess": "Sie wünschen einen Online-Demo Zugang",
      "demoDescription": "Falls Sie Informationsmaterial, Beratung oder ein Angebot wünschen, schreiben Sie einfach eine eMail an die nebenstehende Adresse.",
      "home": "Home",
      "imprint": "imprint",
      "lTechnician": {
        "description": "Der Vor-Ort-Techniker kann entweder ein Mitarbeiter des Maschinennutzers oder der Servicetechniker des Maschinenherstellers sein.",
        "title": "Local Technician"
      },
      "mail": "sales@webware-experts.de",
      "manager": {
        "description": "Der Manager verwaltet sämtliche Störfälle und Aufgaben, die seinem Unternehmen betreffen. Er nimmt die Störmeldungen der Kunden entgegen und weißt seinen Mitarbeitern diese zu, um die Probleme schnellst möglich aus der Welt zu schaffen.",
        "title": "Manager"
      },
      "rTechnician": {
        "description": "Der Remoteexperte kann an seinem Schreibtisch-PC arbeiten und die Whiteboard-Applikation über einen herkömmlichen Browser nutzen.",
        "title": "Remote Technician"
      },
      "title": "Service Managing",
      "titleDescriptionOne": "Die Service Managing Software ermöglicht neue Wege der effektiven Verwaltung",
      "titleDescriptionTwo": "von Vor-Ort-Techniker und Remoteexperten.",
      "toggleNavigation": "Toggle navigation",
      "we": "Copyright &copy; Webware Experts OHG 2017"
    },
    "introductionOne": "Welcome to the Administration-Part of this App",
    "introductionTre": "Please, choose your desired compartment",
    "introductionTwo": "In this section you can change any value of existing user or customer and you can create completely new user and customers",
    "kindOfCategory": "Kind of Category",
    "lTechnician": "L. Technician",
    "lastname": "Lastname",
    "latitude": "Latitude",
    "location": {
      "delete": "delete Location",
      "deleteQuestion": "Do you really want to delete the Location named",
      "detail": "Location Detail",
      "edit": "Location Edit",
      "locations": "Locations",
      "new": "new Location"
    },
    "login": "Login",
    "logout": "Logout",
    "longitude": "Longitude",
    "machine": {
      "delete": "delete Machine",
      "deleteQuestion": "Do you really want to delete the Machine named",
      "detail": "Machine Detail",
      "edit": "Machine Edit",
      "machine": "Machine",
      "machines": "Machines",
      "new": "new Machine",
      "newType": "new Machine Type",
      "noActive": "no active Machines available, no incident creating possible",
      "type": "Machine Type",
      "types": "Machine Types"
    },
    "manager": "Manager",
    "message": "Message",
    "name": "Name",
    "newComment": "Create a Comment",
    "next": "Next",
    "no": "Nein",
    "noComments": "no comments written",
    "noDiagnosis": "no diagnosis diagnosed",
    "note": "Note",
    "number": "Number",
    "overview": "Overview",
    "pagination": "Pagination",
    "password": "Password",
    "phone": "Phone",
    "postcode": "Postcode",
    "previous": "Previous",
    "priority": "Priority",
    "question": "Question",
    "rTechnician": "R. Technician",
    "reallyDelete": "really delete",
    "redirectShortly": "You will be redirected shortly",
    "reset": "Reset",
    "role": "Role",
    "save": "Save",
    "send": "Send",
    "serialNumber": "Serial Number",
    "show": "show",
    "state": "State",
    "status": "State",
    "street": "Street",
    "subject": "Subject",
    "suggest": "suggest",
    "switchLanguage": "Language",
    "switchRole": "switch Role",
    "task": {
      "abort": {
        "explanation": "abort working on this task",
        "really": "do you really want to abort the work on this Task?",
        "title": "Abort"
      },
      "abortCreate": "abort create Task",
      "abortEdit": "abort edit Task",
      "accept": {
        "explanation": "Accept this task to your doing list",
        "title": "Accept"
      },
      "actions": "Actions with this Task",
      "active": "active Tasks",
      "all": "all Tasks",
      "askCustomer": {
        "explanation": "ask customer that this task is really finished",
        "title": "ask Customer"
      },
      "cancel": {
        "explanation": "cancel finishing process of this task",
        "title": "Cancel"
      },
      "cancelWork": {
        "explanation": "cancel the work on this task",
        "title": "Cancel"
      },
      "clear": {
        "explanation": "empty the complete diagnosis field",
        "title": "Clear"
      },
      "closingWork": {
        "explanation": "closing this task as finished",
        "title": "Close"
      },
      "complete": {
        "explanation": "complete this task",
        "title": "Complete"
      },
      "conclusion": "Task Conclusion",
      "confirmCreate": "confirm create Task",
      "confirmEdit": "confirm edit Task",
      "createTask": {
        "explanation": "generate a new task for selected incident",
        "title": "Create Task"
      },
      "current": "current Task",
      "edit": {
        "explanation": "edit properties of this task",
        "title": "Edit"
      },
      "events": "Events for this task",
      "finish": {
        "explanation": "go to finish process of this task",
        "title": "Finish"
      },
      "finished": {
        "explanation": "close this task to completly finished",
        "title": "Finished"
      },
      "history": "Task History",
      "management": "Task Management",
      "noActive": "No active Task",
      "noProgress": "no task in progress",
      "pause": {
        "explanation": "pause working on this task",
        "title": "Pause"
      },
      "process": {
        "explanation": "begin to process this task",
        "title": "Process"
      },
      "processing": "Task Processing",
      "reassign": {
        "explanation": "reassign this task to an other technician",
        "title": "Reassign"
      },
      "reject": {
        "explanation": "reject this task",
        "really": "Do you really want to reject this Task",
        "title": "Reject"
      },
      "selected": "selected Task",
      "submit": {
        "explanation": "submit the diagnosis input",
        "title": "Submit"
      },
      "switchTo": "switch to Task",
      "task": "Task",
      "tasks": "Tasks"
    },
    "technician": "Technician",
    "technicianOverview": "Technician Overview",
    "time": "Time",
    "toggleNavigation": "Toggle navigation",
    "unseen": "unseen",
    "user": {
      "deleteQuestion": "Do you really want to delete the User named",
      "edit": "User Edit",
      "info": "User Info",
      "new": "new User",
      "user": "User",
      "users": "Users"
    },
    "weManage": "weManage",
    "weManageLogo": "weManage Logo",
    "year": "Year of Construction",
    "yes": "Ja",
    "youAreA": "Actual you act as a"
  };
  _exports.default = _default;
});