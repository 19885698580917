define("managing-tool-frontend/we-manage/administration/accounts/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['usersOffset'],
    usersCurrentPage: 1,
    usersTotalPages: 0,
    usersOffset: 0,
    actions: {
      changePage(whatWhere) {
        let page = this.get('usersCurrentPage');

        if (whatWhere == 1) {
          this.set('usersCurrentPage', page + 1);
          this.set('usersOffset', page * 6);
        } else {
          this.set('usersCurrentPage', page - 1);
          this.set('usersOffset', (page - 2) * 6);
        }
      },

      reloadModel() {
        this.send("refreshModel");
      }

    }
  });

  _exports.default = _default;
});