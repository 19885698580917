define("managing-tool-frontend/we-manage/route", ["exports", "jquery", "managing-tool-frontend/app", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _jquery, _app, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    defaultRole: null,
    init: function () {
      this._super(...arguments);

      let that = this;
      let lsat = JSON.parse(localStorage.getItem("ember_simple_auth-session"))["authenticated"]["access_token"];

      _jquery.default.ajax({
        type: "GET",
        url: _app.default.API_HOST + "/api/whoami",
        data: "",
        processData: false,
        contentType: false,
        headers: {
          Authorization: "Bearer " + lsat
        },
        success: function (data, textStatus) {
          if (typeof data.error === "undefined") {
            that.get("session").set('data.user', data);
            that.controllerFor("weManage").set("myName", data.firstname + " " + data.lastname);
            that.set('defaultRole', data.defaultRole);
            that.controllerFor("weManage").myRole({
              id: data.defaultRole
            });
            that.controllerFor("weManage").whichRole(data.roles);
            that.controllerFor("weManage").gotoThisPart(data.defaultRole);
          } else {
            console.log("textStatus: ", textStatus);
          }
        },
        error: function (jqXHR, textStatus) {
          that.get("session").invalidate();
          console.log("textStatus: ", textStatus);
        }
      });
    },

    deactivate() {
      (0, _jquery.default)('[data-toggle="tooltip"]').tooltip("hide");
      this.controllerFor("weManage").set("activeRole", null);
      window.localStorage.removeItem("jwt");
    },

    actions: {
      willTransition: function (transition) {
        let targetName = transition.targetName;

        if (targetName === "weManage.index") {
          let defaultRole = this.get("defaultRole");
          this.controllerFor("weManage").gotoThisPart(defaultRole);
        }
      }
    }
  });

  _exports.default = _default;
});