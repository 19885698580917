define("managing-tool-frontend/we-manage/service-management/history/tasks/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      historyTasksOffset: {
        refreshModel: true
      }
    },

    renderTemplate() {
      this.render("weManage/serviceManagement/history/tasks", {
        into: "weManage",
        outlet: "maincontent"
      });
    },

    model(params) {
      return this.store.query("task", {
        states: '3!7',
        limit: 6,
        offset: params.historyTasksOffset
      }).then(tasks => {
        return {
          tasks: tasks,
          tasksM: tasks.get("meta")
        };
      });
    },

    afterModel({
      tasks
    }) {
      tasks.forEach(function (task) {
        task.set("selected", false);
      });
    },

    setupController(controller, {
      tasks,
      tasksM
    }) {
      this._super(controller, {
        tasks,
        tasksM
      });

      controller.set("tasksTotalPages", Math.ceil(tasksM.count / 6));
    },

    redirect({
      tasks
    }, transition) {
      if (transition.targetName === this.routeName + ".index") {
        if (tasks != null) {
          if (tasks.get("length") > 0) {
            this.transitionTo("weManage.serviceManagement.history.tasks.task", tasks.get("firstObject"));
          }
        }
      }
    },

    actions: {
      loadTask: function (task) {
        this.transitionTo("weManage.serviceManagement.history.tasks.task", task);
      }
    }
  });

  _exports.default = _default;
});