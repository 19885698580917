define("managing-tool-frontend/we-manage/service-management/history/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      this.controllerFor("weManage.serviceManagement").send("switchBreadcrumb", 5);
    },

    renderTemplate: function () {
      this.render("weManage/serviceManagement/history", {
        into: "weManage",
        outlet: "maincontent"
      });
    },
    actions: {
      managementIncidentHistory: function () {
        this.transitionTo("weManage.serviceManagement.history.incidents");
      },
      managementTaskHistory: function () {
        this.transitionTo("weManage.serviceManagement.history.tasks");
      }
    }
  });

  _exports.default = _default;
});