define("managing-tool-frontend/templates/navigation/administration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qKBHZc57",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"AisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"users\"],null],false],[0,\" \"],[1,[27,\"t\",[\"user.users\"],null],false],[3,\"action\",[[22,0,[]],\"loadAccounts\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"CisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"tags\"],null],false],[0,\" \"],[1,[27,\"t\",[\"customer.customers\"],null],false],[3,\"action\",[[22,0,[]],\"loadCustomers\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"TisActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"folder-open\"],null],false],[0,\" \"],[1,[27,\"t\",[\"machine.types\"],null],false],[3,\"action\",[[22,0,[]],\"loadMachinetypes\"]],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/templates/navigation/administration.hbs"
    }
  });

  _exports.default = _default;
});