define("managing-tool-frontend/models/incident", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    subject: _emberData.default.attr(),
    description: _emberData.default.attr(),
    state: _emberData.default.attr(),
    created: _emberData.default.attr(),
    appointment: _emberData.default.attr(),
    priority: _emberData.default.attr(),
    tasks: _emberData.default.hasMany("task"),
    creator: _emberData.default.belongsTo("user"),
    diagnosis: _emberData.default.belongsTo("diagnosis"),
    machine: _emberData.default.belongsTo("machine"),
    incidentComments: _emberData.default.hasMany("incidentComment", {
      async: true
    }),
    incidentLogs: _emberData.default.hasMany("incidentLog", {
      async: true
    }),
    selected: false,
    name: Ember.computed("subject", function () {
      return this.get("subject");
    }),
    fDate: Ember.computed("created", function () {
      let reDate = new Date(this.get("created"));
      return reDate.toLocaleDateString("de-DE", {
        month: "2-digit",
        day: "2-digit"
      });
    }),
    fTime: Ember.computed("created", function () {
      let reDate = new Date(this.get("created"));
      return reDate.toLocaleTimeString();
    }),
    fDDate: Ember.computed("appointment", function () {
      let reDate = new Date(this.get("appointment"));
      return reDate.toLocaleDateString("de-DE", {
        month: "2-digit",
        day: "2-digit"
      });
    }),
    fDTime: Ember.computed("appointment", function () {
      let reDate = new Date(this.get("appointment"));
      return reDate.toLocaleTimeString();
    })
  });

  _exports.default = _default;
});