define("managing-tool-frontend/models/diagnosis", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    text: _emberData.default.attr(),
    creator: _emberData.default.attr(),
    created: _emberData.default.attr(),
    incident: _emberData.default.belongsTo("incident"),
    fDate: Ember.computed("created", function () {
      let options = {
        month: "2-digit",
        day: "2-digit"
      };
      let jsonDate = this.get("created");
      let reDate = new Date(jsonDate);
      return reDate.toLocaleDateString("de-DE", options);
    }),
    fTime: Ember.computed("created", function () {
      let jsonDate = this.get("created");
      let reDate = new Date(jsonDate);
      return reDate.toLocaleTimeString();
    })
  });

  _exports.default = _default;
});