define("managing-tool-frontend/components/select-array/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    content: null,
    selectedValue: null,

    init() {
      this._super(...arguments);

      let content = this.get("content");

      if (!content) {
        this.set("content", []);
      }
    },

    actions: {
      change() {
        const changeAction = this.get("action");
        const selectedEl = this.$("select")[0];
        const selectedIndex = selectedEl.selectedIndex;
        const content = this.get("content");
        const selectedValue = content[selectedIndex];
        this.set("selectedValue", selectedValue);
        changeAction(selectedValue);
      }

    }
  });

  _exports.default = _default;
});