define("managing-tool-frontend/we-manage/administration/customers/customer/machines/machine/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      deleteMachine: function (machine) {
        this.transitionToRoute("weManage.administration.customers.customer.machines");
        this.store.findRecord("machine", machine.id).then(function (nothing) {
          nothing.destroyRecord();
        });
      },
      editMachine: function () {
        this.transitionToRoute("weManage.administration.customers.customer.machines.machine.edit");
      }
    }
  });

  _exports.default = _default;
});