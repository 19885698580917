define("managing-tool-frontend/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "managing-tool-frontend/app"], function (_exports, _oauth2PasswordGrant, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _app.default.API_HOST + '/api/login'
  });

  _exports.default = _default;
});