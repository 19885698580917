define("managing-tool-frontend/components/customerinfo-panel/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editable: Ember.computed("edit", function () {
      return this.edit;
    }),
    actions: {
      toggleCustomerPanel: function () {
        let displayNow = document.getElementById("CustomerDetail");

        if (displayNow.style.display === "none") {
          displayNow.style.display = "table";
        } else {
          displayNow.style.display = "none";
        }
      },
      deleteCustomer: function (customer) {
        (0, _jquery.default)("#deleteCustomerModal").modal("hide");
        this.deleteCustomer(customer);
      },
      editCustomer: function () {
        this.editCustomer();
      },
      showMachines: function () {
        this.showMachines();
      },
      showLocations: function () {
        this.showLocations();
      }
    }
  });

  _exports.default = _default;
});