define("managing-tool-frontend/we-manage/customer/history/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      historyIncidentsOffset: {
        refreshModel: true
      }
    },

    activate() {
      this.controllerFor("weManage.customer").send("switchBreadcrumb", 3);
    },

    renderTemplate() {
      this.render("weManage/customer/history", {
        into: "weManage",
        outlet: "maincontent"
      });
    },

    model(params) {
      return this.store.query("incident", {
        states: '3!4!13!14',
        limit: 6,
        offset: params.historyIncidentsOffset
      }).then(incidents => {
        return {
          incidents: incidents,
          incidentsM: incidents.get("meta")
        };
      });
    },

    afterModel(model) {
      model.incidents.forEach(function (incident) {
        incident.set("selected", false);
      });
    },

    setupController(controller, {
      incidents,
      incidentsM
    }) {
      this._super(controller, {
        incidents,
        incidentsM
      });

      controller.set("incidentsTotalPages", Math.ceil(incidentsM.count / 6));
    },

    redirect({
      incidents
    }, transition) {
      if (transition.targetName === this.routeName + ".index") {
        if (incidents != null) {
          if (incidents.get("length") > 0) {
            this.transitionTo("weManage.customer.history.incident", incidents.get("firstObject"));
          }
        }
      }
    },

    actions: {
      loadIncident: function (incident) {
        this.transitionTo("weManage.customer.history.incident", incident);
      }
    }
  });

  _exports.default = _default;
});