define("managing-tool-frontend/we-manage/administration/accounts/account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B6NRAk3a",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[\"userinfo-panel\"],[[\"user\",\"edit\",\"deleteUser\",\"editUser\"],[[23,[\"model\"]],true,[27,\"action\",[[22,0,[]],\"deleteUser\"],null],[27,\"action\",[[22,0,[]],\"editUser\"],null]]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/we-manage/administration/accounts/account/template.hbs"
    }
  });

  _exports.default = _default;
});