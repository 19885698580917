define("managing-tool-frontend/we-manage/service-management/task-management/new/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    taskController: Ember.inject.controller('weManage.serviceManagement.taskManagement'),

    init() {
      this._super(...arguments);

      this.allPriority = ["NORMAL", "HIGH", "CRITICAL"];
    },

    selPriority: null,
    selTechnician: null,
    allTechnicians: Ember.computed.filter('model.users', function (user) {
      if ((user.roles & 4) !== 0 || (user.roles & 8) !== 0) {
        return user;
      }
    }),
    dateTimeNow: Ember.computed('', function () {
      return (0, _moment.default)().format();
    }),
    actions: {
      createButton: function () {
        let that = this;
        let jsonAppointment = new Date().toJSON();
        let selPriority = this.get("selPriority");

        if (selPriority === "HIGH") {
          this.set("selPriority", 2);
        } else if (selPriority === "CRITICAL") {
          this.set("selPriority", 3);
        } else {
          this.set("selPriority", 1);
        }

        let usedIncident = this.get("incident");
        let subject = this.get("subject");

        if (subject === undefined || subject === "") {
          if (usedIncident !== null) {
            subject = usedIncident.get("subject");
          }
        }

        let description = this.get("description");

        if (description === undefined || description === "") {
          if (usedIncident !== null) {
            description = usedIncident.get("description");
          }
        }

        let selTechnician = this.get("selTechnician");

        if (selTechnician === null) {
          selTechnician = this.get("allTechnicians").get("firstObject");
        }

        let chosenDate = this.get("chosenDate");

        if (chosenDate) {
          jsonAppointment = chosenDate.toJSON();
        }

        let newTask = this.get('model.task');
        newTask.set('incident', usedIncident);
        newTask.set('subject', subject);
        newTask.set('description', description);
        newTask.set('priority', this.get("selPriority"));
        newTask.set('appointment', jsonAppointment);
        newTask.set('assignedUser', selTechnician);
        this.get("taskController").send("reloadModel");
        usedIncident.set("state", 5);
        usedIncident.save().then(function () {
          newTask.save().then(function () {
            that.set("subject", "");
            that.set("description", "");
            return that.transitionToRoute("weManage.serviceManagement.taskManagement");
          });
        });
      },
      abortButton: function () {
        this.set("subject", "");
        this.set("description", "");
        return this.transitionToRoute("weManage.serviceManagement.taskManagement");
      }
    }
  });

  _exports.default = _default;
});