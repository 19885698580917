define("managing-tool-frontend/authorizers/oauth2", ["exports", "ember-simple-auth/authorizers/oauth2-bearer"], function (_exports, _oauth2Bearer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2Bearer.default.extend();

  _exports.default = _default;
});