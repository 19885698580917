define("managing-tool-frontend/components/incident-comment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LAnSfpaa",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"fa-icon\",[[23,[\"fType\"]]],null],false],[0,\" \"],[7,\"small\"],[9],[1,[23,[\"comment\",\"fDate\"]],false],[0,\" | \"],[1,[23,[\"comment\",\"fTime\"]],false],[0,\" - \"],[1,[23,[\"comment\",\"creator\"]],false],[10],[0,\" >> \"],[1,[23,[\"comment\",\"text\"]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/components/incident-comment/template.hbs"
    }
  });

  _exports.default = _default;
});