define("managing-tool-frontend/we-manage/administration/customers/customer/machines/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    name: null,
    serialNumber: null,
    allTypes: Ember.computed("machineType", function () {
      return this.store.findAll("machineType");
    }),
    selectedType: null,
    actions: {
      saveAndGoBack: function () {
        let selMachine = this.get("selMachine");

        if (selMachine === null) {
          selMachine = this.get("allMachines").get("firstObject");
        }

        let newMachine = this.store.createRecord("machine", {
          name: this.get("name"),
          serialNumber: this.get("serialNumber"),
          year: this.get("year"),
          machineType: this.get("selectedType"),
          customer: this.get("customer")
        });
        newMachine.save();
        this.set("name", "");
        this.set("serialNumber", "");
        this.set("selectedType", null);
        return this.transitionToRoute("weManage.administration.customers.customer.machines");
      },
      abortAndGoBack: function () {
        this.set("name", "");
        this.set("serialNumber", "");
        this.set("selectedType", null);
        return this.transitionToRoute("weManage.administration.customers.customer.machines");
      }
    }
  });

  _exports.default = _default;
});