define("managing-tool-frontend/we-manage/administration/customers/customer/locations/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      saveAndGoBack: function () {
        let newLocation = this.store.createRecord("location", {
          name: this.get("name"),
          street: this.get("street"),
          number: this.get("number"),
          zipCode: this.get("zipCode"),
          city: this.get("city"),
          state: this.get("state"),
          country: this.get("country"),
          latitude: this.get("latitude"),
          longitude: this.get("longitude"),
          customer: this.get("location")
        });
        newLocation.save();
        this.set("name", "");
        this.set("street", "");
        this.set("number", "");
        this.set("zipCode", "");
        this.set("city", "");
        this.set("state", "");
        this.set("country", "");
        this.set("latitude", "");
        this.set("longitude", "");
        return this.transitionToRoute("weManage.administration.customers.customer.locations");
      },
      abortAndGoBack: function () {
        this.set("name", "");
        this.set("street", "");
        this.set("number", "");
        this.set("zipCode", "");
        this.set("city", "");
        this.set("state", "");
        this.set("country", "");
        this.set("latitude", "");
        this.set("longitude", "");
        return this.transitionToRoute("weManage.administration.customers.customer.locations");
      }
    }
  });

  _exports.default = _default;
});