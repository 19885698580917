define("managing-tool-frontend/we-manage/service-processing/task-processing/task-details/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      (0, _jquery.default)(function () {
        (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();
      });
    },

    model: function () {
      return this.modelFor("weManage.serviceProcessing.taskProcessing");
    },

    setupController(controller, model) {
      this._super(controller, model);

      model.get("incident").then(function (incident) {
        controller.set("incident", incident);
        incident.get("machine").then(function (machine) {
          controller.set("machine", machine);
          machine.get("customer").then(function (customer) {
            controller.set("customer", customer);
            machine.get("machineType").then(function (machineType) {
              controller.set("machineType", machineType);
            });
          });
        });
      });
    },

    renderTemplate() {
      this.render("machinedetails", {
        into: "weManage/serviceProcessing/taskProcessing",
        outlet: "taskAlpha"
      });
      this.render("weManage/serviceProcessing/taskProcessing/taskDetails", {
        into: "weManage/serviceProcessing/taskProcessing",
        outlet: "taskBeta"
      });
      this.render("customerdetails", {
        into: "weManage/serviceProcessing/taskProcessing",
        outlet: "taskGamma"
      });
    },

    deactivate() {
      (0, _jquery.default)('[data-toggle="tooltip"]').tooltip('hide');
    }

  });

  _exports.default = _default;
});