define("managing-tool-frontend/we-manage/controller", ["exports", "managing-tool-frontend/config/environment", "ember-intl/hydrate"], function (_exports, _environment, _hydrate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    modulePrefix
  } = _environment.default;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    activeLocale: Ember.computed.readOnly('intl.locale'),
    chatRegistered: true,
    activeRole: "User",
    myName: "User",
    allUsedRoles: null,

    init() {
      this._super(...arguments);
    },

    allRoles: Ember.computed('activeLocale', function () {
      let allRoles = [{
        id: 1,
        name: this.intl.t('admin')
      }, {
        id: 2,
        name: this.intl.t('manager')
      }, {
        id: 4,
        name: this.intl.t('rTechnician')
      }, {
        id: 8,
        name: this.intl.t('lTechnician')
      }, {
        id: 16,
        name: this.intl.t('customer.customer')
      }, {
        id: 32,
        name: this.intl.t('user.user')
      }];
      return allRoles;
    }),

    myRole(chosenRole) {
      if (!chosenRole) {
        chosenRole = this.get("activeRole");
      }

      let allRoles = this.get('allRoles');
      let activeRole;

      switch (chosenRole.id) {
        case 1:
          activeRole = allRoles[0];
          break;

        case 2:
          activeRole = allRoles[1];
          break;

        case 4:
          activeRole = allRoles[2];
          break;

        case 8:
          activeRole = allRoles[3];
          break;

        case 16:
          activeRole = allRoles[4];
          break;

        default:
          activeRole = allRoles[5];
          break;
      }

      this.set("activeRole", activeRole);
    },

    whichRole(roles) {
      let multiRole = false;
      let allRoles = this.get('allRoles');
      let roleList = [];

      if ((roles & 1) !== 0) {
        roleList.addObject(allRoles[0]);
      }

      if ((roles & 2) !== 0) {
        roleList.addObject(allRoles[1]);
      }

      if ((roles & 4) !== 0) {
        roleList.addObject(allRoles[2]);
      }

      if ((roles & 8) !== 0) {
        roleList.addObject(allRoles[3]);
      }

      if ((roles & 16) !== 0) {
        roleList.addObject(allRoles[4]);
      }

      if (roleList.length > 1) {
        multiRole = true;
      }

      this.set("multiRole", multiRole);
      this.set("allUsedRoles", roleList);
    },

    gotoThisPart(myRole) {
      switch (myRole) {
        case 1:
          return this.transitionToRoute("weManage.administration");

        case 2:
          return this.transitionToRoute("weManage.serviceManagement");

        case 4:
          return this.transitionToRoute("weManage.serviceProcessing");

        case 8:
          return this.transitionToRoute("weManage.serviceProcessing");

        case 16:
          return this.transitionToRoute("weManage.customer");

        default:
          return this.transitionToRoute("weManage.customer");
      }
    },

    locales: Ember.computed(function () {
      return (0, _hydrate.lookupByFactoryType)('translations', modulePrefix).map(moduleName => moduleName.split('/').pop());
    }).readOnly(),
    selections: Ember.computed('locales.[]', 'activeLocale', function () {
      let active = Ember.get(this, 'activeLocale');
      let thisUser = this.get("session").get('data.user');

      if (thisUser) {
        this.whichRole(thisUser.roles);
        this.myRole();
      }

      return Ember.get(this, 'locales').map(locale => {
        return {
          locale: locale,
          active: active.indexOf(locale) > -1
        };
      });
    }).readOnly(),
    actions: {
      roleChoosen: function (role) {
        this.set("activeRole", role);
        this.gotoThisPart(role.id);
      },
      switchLanguage: function (toThis) {
        return Ember.get(this, 'intl').set('locale', toThis);
      },
      logout: function () {
        this.set("activeRole", null);
        this.get("session").invalidate();
      }
    }
  });

  _exports.default = _default;
});