define("managing-tool-frontend/we-manage/administration/customers/customer/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    allUsers: Ember.computed("user", function () {
      return this.store.query("customer", {
        limit: 18,
        offset: 0
      });
    }),
    selectedUser: null,
    actions: {
      saveAndGoBack: function () {
        let customer = this.get("model");
        customer.set("user", this.get("selectedUser"));
        customer.save();
        this.set("selectedUser", null);
        return this.transitionToRoute("weManage.administration.customers.customer");
      },
      abortAndGoBack: function () {
        this.get("model").rollbackAttributes();
        this.set("selectedUser", null);
        return this.transitionToRoute("weManage.administration.customers.customer");
      }
    }
  });

  _exports.default = _default;
});