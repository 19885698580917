define("managing-tool-frontend/translations/es-es", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "abort": "Abortar",
    "addComment": "Añadir un comentario",
    "admin": "Administración",
    "administration": "Administración",
    "annotate": "Nota",
    "answer": "Respuesta",
    "appointment": "Cita",
    "assignedTechnician": "Técnico asignado",
    "back": "Atrás",
    "cancel": "Abortar",
    "categoryName": "Nombre de la categoría",
    "changeRole": "Cambia su rol",
    "changeToRole": "Para cambiar su rol activo, elija un rol que se le asigne",
    "chat": "Charlar",
    "chooseHistoryArea": "Por favor seleccione su área de historia deseada",
    "city": "Ciudad",
    "close": "Cerrar",
    "commentExplanation": "Escribe sus notas que quieres comentar",
    "comments": "Comentarios",
    "companyName": "Nombre de empresa",
    "country": "País",
    "create": "Redactar",
    "created": "Creado",
    "creator": "Creador",
    "creatorCreated": "Creador/Creado",
    "customer": {
      "customer": "Cliente",
      "customers": "Clientes",
      "delete": "Eliminar cliente",
      "deleteQuestion": "¿Estás seguro de que quieres borrar el cliente, nombre",
      "edit": "Cambio de cliente",
      "info": "La información del cliente",
      "locations": "Lugares de los clientes",
      "machines": "Máquinas de los clientes",
      "new": "Nuevo cliente"
    },
    "date": "Fecha",
    "defaultRole": "Rol estándar",
    "delete": "Borrar",
    "description": "Descripción",
    "diagnosis": "Diagnóstico",
    "edit": "Cambio",
    "email": "Email",
    "finalReport": "Informe final",
    "firstname": "Primer nombre",
    "hide": "Ocultar",
    "history": "Historia",
    "historyIntroductionOne": "Bienvenido a la sección historial de gestión de servicios de esta aplicación",
    "historyIntroductionTwo": "En esta sección puede ver todos los incidentes y tareas que haya aceptado, coordinado, rechazado o completado.",
    "id": "Identificación",
    "in": "en",
    "inProgress": "En progreso",
    "incident": {
      "abortCreate": "Abortar crear incidente",
      "abortCreateIncident": {
        "explanation": "Cancela la creación de este incidente.",
        "title": "Cancelar incidente"
      },
      "accept": {
        "explanation": "Acepta este incidente como completo",
        "title": "Aceptar"
      },
      "active": "Incidentes activos",
      "askCreateIncident": {
        "explanation": "Solicitud al cliente de crear un incidente",
        "title": "Solicitud para crear incidente"
      },
      "close": {
        "explanation": "Termina el incidente como completamente hecho",
        "title": "Completar incidente"
      },
      "confirm": {
        "explanation": "Confirmar que el incidente existe realmente",
        "title": "Confirmar"
      },
      "confirmCreate": "Confirma crear un incidente",
      "confirmCreateIncident": {
        "explanation": "Definitivamente crear este incidente",
        "title": "Confirmar crear incidente"
      },
      "create": "Crear mal funcionamiento",
      "detail": "Detalle de incidente",
      "history": "Incidente pasado",
      "inProgress": "Incidente en proceso",
      "incident": "Incidente",
      "incidents": "Incidentes",
      "info": "Información del incidente",
      "interrupt": {
        "explanation": "Solicitud para cancelar este incidente.",
        "title": "Interrumpir"
      },
      "invalid": {
        "explanation": "Rechaza este incidente",
        "title": "Inválido"
      },
      "management": "Gestión de incidencias",
      "noActive": "no hay incidentes activos",
      "reject": {
        "explanation": "Rechazar este incidente como NO hecho",
        "title": "Rechazar"
      },
      "rejected": "Incidentes rechazados",
      "selected": "Incidente seleccionado",
      "source": "Fuente de incidente",
      "switchTo": "Cambiar a incidentes",
      "unedited": "Incidente sin procesar",
      "verify": {
        "explanation": "Pregunta al cliente si el incidente está completamente resuelto.",
        "title": "Confirmar la finalización"
      },
      "verifys": "Incidentes confirmados"
    },
    "intro": {
      "admin": {
        "description": "El Administrador tiene la capacidad de incorporar y administrar datos superiores, como la información general del cliente, en la aplicación. El administrador suele ser un empleado del proveedor del equipo.",
        "title": "Administrador"
      },
      "contact": "Contacto",
      "demoAccess": "¿Quiere un acceso demo online?",
      "demoDescription": "Si desea material informativo, asesoramiento o una oferta, escriba un correo electrónico a la dirección que se encuentra a continuación.",
      "home": "Home",
      "imprint": "Aviso legal",
      "lTechnician": {
        "description": "El técnico en el sitio puede ser un empleado del usuario de la máquina o el técnico de servicio del fabricante de la máquina.",
        "title": "Técnico local"
      },
      "mail": "sales@webware-experts.de",
      "manager": {
        "description": "El manager administra todos los incidentes y tareas que afectan a su empresa. Toma los mensajes de defectos del cliente y los asigna a sus empleados para eliminar los problemas lo más rápido posible.",
        "title": "El manager"
      },
      "rTechnician": {
        "description": "El experto remoto puede trabajar en su PC de escritorio y usar la aplicación de pizarra a través de un navegador convencional.",
        "title": "Técnico remoto"
      },
      "title": "Gestión de servicios",
      "titleDescriptionOne": "El software de gestión de servicios permite nuevas formas de gestión efectiva.",
      "titleDescriptionTwo": "De técnicos locales y expertos remotos",
      "toggleNavigation": "Cambiar de navigación",
      "we": "Copyright &copy; Webware Experts OHG 2017"
    },
    "introductionOne": "Bienvenido a la sección de administración de esta aplicación",
    "introductionTre": "Por favor elija su área deseada",
    "introductionTwo": "En esta sección, puede cambiar todos los valores de los usuarios y clientes existentes y también crear usuarios y clientes completamente nuevos.",
    "kindOfCategory": "Tipo de categoria",
    "lTechnician": "Técnico local",
    "lastname": "Apellido",
    "latitude": "Latitud",
    "location": {
      "delete": "Eliminar ubicación",
      "deleteQuestion": "¿Está seguro de que desea eliminar la ubicación, el nombre",
      "detail": "Detalles de la ubicación",
      "edit": "Cambiar ubicación",
      "locations": "Ubicaciones",
      "new": "Nueva ubicación"
    },
    "login": "Acceder",
    "logout": "Finalizar la sesión",
    "longitude": "Longitud",
    "machine": {
      "delete": "Eliminar la maquina",
      "deleteQuestion": "¿Realmente quieres borrar la máquina, nombre",
      "detail": "Detalles del máquina",
      "edit": "Cambio de maquina",
      "machine": "Máquina",
      "machines": "Maquinaria",
      "new": "Nueva máquina",
      "newType": "Nuevo tipo de máquina",
      "noActive": "No hay máquinas activas disponibles, por lo tanto, es imposible crear un incidente.",
      "type": "Tipo de máquina",
      "types": "Tipos de máquinas"
    },
    "manager": "Manager",
    "message": "Mensaje",
    "name": "Nombre",
    "newComment": "Crear comentarios",
    "next": "Siguiente",
    "no": "No",
    "noComments": "No hay comentarios escritos",
    "noDiagnosis": "Sin diagnóstico creado",
    "note": "Nota",
    "number": "Número",
    "overview": "Estudio",
    "pagination": "Paginación",
    "password": "Reiniciar",
    "phone": "Teléfono",
    "postcode": "Código postal",
    "previous": "Antes",
    "priority": "Prioridad",
    "question": "Pregunta",
    "rTechnician": "Técnico remoto",
    "reallyDelete": "Realmente eliminar",
    "redirectShortly": "Serás redirigido en breve.",
    "reset": "Regreso",
    "role": "Papel",
    "save": "Asegurar",
    "send": "Enviar",
    "serialNumber": "Número de serie",
    "show": "Mostrar",
    "state": "Estado",
    "status": "Estatus",
    "street": "Calle",
    "subject": "Respecto",
    "suggest": "Sugerir",
    "switchLanguage": "Idioma",
    "switchRole": "cambiar Papel",
    "task": {
      "abort": {
        "explanation": "Dejar de trabajar en esta tarea  finish",
        "really": "¿Realmente quieres abortar trabajar en esta tarea?",
        "title": "Abortar"
      },
      "abortCreate": "Cancelar la creación de tareas",
      "abortEdit": "Cancelar cambio de tarea",
      "accept": {
        "explanation": "Acepta esta tarea como suya reject",
        "title": "Aceptar"
      },
      "actions": "Acciones con esta tarea",
      "active": "tarea activa",
      "all": "Todas las tareas",
      "askCustomer": {
        "explanation": "Pregunta al cliente si esta tarea realmente ha terminado",
        "title": "Preguntar a los clientes"
      },
      "cancel": {
        "explanation": "Cancelar la finalización de esta tarea.",
        "title": "Cancelar"
      },
      "cancelWork": {
        "explanation": "Cancelar el trabajo en esta tarea.",
        "title": "Cancelar"
      },
      "clear": {
        "explanation": "Vaciar la caja diagnóstica completa",
        "title": "Vaciar"
      },
      "closingWork": {
        "explanation": "Cierra esta tarea como hecho",
        "title": "Cerrar"
      },
      "complete": {
        "explanation": "Completa esta tarea",
        "title": "Completar"
      },
      "conclusion": "Completar la tarea",
      "confirmCreate": "Confirmar Crear Tarea",
      "confirmEdit": "Confirmar cambio de tarea  abortCreate",
      "createTask": {
        "explanation": "Crear una nueva tarea para el incidente seleccionado",
        "title": "Crear una tarea"
      },
      "current": "Tarea actual",
      "edit": {
        "explanation": "Cambiar las características de esta tarea",
        "title": "cambio"
      },
      "events": "Eventos con esta tarea",
      "finish": {
        "explanation": "Completar esta tarea",
        "title": "Concluir"
      },
      "finished": {
        "explanation": "Cierra este ensayo como completamente terminado",
        "title": "Terminado"
      },
      "history": "Historia de la tarea",
      "management": "Tareas de gestión",
      "noActive": "No hay tareas activas",
      "noProgress": "Ninguna tarea en progreso",
      "pause": {
        "explanation": "Pausar el trabajo en esta tarea",
        "title": "Pausa"
      },
      "process": {
        "explanation": "Empieza a trabajar en esta tarea",
        "title": "Procesar"
      },
      "processing": "Trabajar las tareas",
      "reassign": {
        "explanation": "Asignar esta tarea a otro técnico",
        "title": "Reasignar"
      },
      "reject": {
        "explanation": "Rechaza esta tarea",
        "really": "¿Realmente quieres rechazar esta tarea?",
        "title": "Rechazar"
      },
      "selected": "tarea eligida",
      "submit": {
        "explanation": "Transferir el diagnostico",
        "title": "Transmitir"
      },
      "switchTo": "Cambiar a tarea",
      "task": "Tarea",
      "tasks": "Tareas"
    },
    "technician": "Técnico",
    "technicianOverview": "Visión general del técnico",
    "time": "Tiempo",
    "toggleNavigation": "Cambiar de navegación",
    "unseen": "Sin ser visto",
    "user": {
      "deleteQuestion": "¿Seguro que quieres borrar el usuario, nombre?",
      "edit": "Cambiar usuarios",
      "info": "Información del usuario",
      "new": "Nuevo usuario",
      "user": "Usuario",
      "users": "Usuarios"
    },
    "weManage": "weManage",
    "weManageLogo": "Logotipo de weManage",
    "year": "Año de construcción",
    "yes": "Sí",
    "youAreA": "Actualmente estas"
  };
  _exports.default = _default;
});