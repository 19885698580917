define("managing-tool-frontend/we-manage/administration/accounts/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    accountsController: Ember.inject.controller('weManage.administration.accounts'),
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    latitude: null,
    longitude: null,
    allDefaultRoles: Ember.computed("isAdmin", "isManager", "isRemote", "isLocal", "isCustomer", function () {
      let defaultRoleOut = [];

      if (this.get("isAdmin")) {
        defaultRoleOut.addObject("Admin");
      }

      if (this.get("isManager")) {
        defaultRoleOut.addObject("Manager");
      }

      if (this.get("isRemote")) {
        defaultRoleOut.addObject("R. Technician");
      }

      if (this.get("isLocal")) {
        defaultRoleOut.addObject("L. Technician");
      }

      if (this.get("isCustomer")) {
        defaultRoleOut.addObject("Customer");
      }

      return defaultRoleOut;
    }),
    selectedDefaultRole: null,
    multiRole: Ember.computed("isAdmin", "isManager", "isRemote", "isLocal", "isCustomer", function () {
      return this.get("allDefaultRoles").length > 1;
    }),
    selCustomer: null,
    allCustomers: Ember.computed("customer", function () {
      return this.store.query("customer", {
        limit: 18,
        offset: 0
      });
    }),
    actions: {
      saveAndGoBack: function () {
        let selectedRole = 0;
        let defaultRole = 0;

        if (this.get("isCustomer")) {
          selectedRole += 16;
          defaultRole = 16;
        }

        if (this.get("isLocal")) {
          selectedRole += 8;
          defaultRole = 8;
        }

        if (this.get("isRemote")) {
          selectedRole += 4;
          defaultRole = 4;
        }

        if (this.get("isManager")) {
          selectedRole += 2;
          defaultRole = 2;
        }

        if (this.get("isAdmin")) {
          selectedRole += 1;
          defaultRole = 1;
        }

        switch (this.get("selectedDefaultRole")) {
          case "Admin":
            defaultRole = 1;
            break;

          case "Manager":
            defaultRole = 2;
            break;

          case "R. Technician":
            defaultRole = 4;
            break;

          case "L. Technician":
            defaultRole = 8;
            break;

          case "Customer":
            defaultRole = 16;
            break;
        }

        let newUser = this.store.createRecord("user", {
          firstname: this.get("firstname"),
          lastname: this.get("lastname"),
          email: this.get("email"),
          phone: this.get("phone"),
          roles: selectedRole,
          defaultRole: defaultRole,
          latitude: this.get("latitude"),
          longitude: this.get("longitude"),
          customer: this.get("selCustomer")
        });
        newUser.save();
        this.set("firstname", "");
        this.set("lastname", "");
        this.set("email", "");
        this.set("phone", "");
        this.set("isAdmin", false);
        this.set("isManager", false);
        this.set("isRemote", false);
        this.set("isLocal", false);
        this.set("isCustomer", false);
        this.set("selectedDefaultRole", "");
        this.set("latitude", "");
        this.set("longitude", "");
        this.set("selCustomer", null);
        this.get("accountsController").send("reloadModel");
        return this.transitionToRoute("weManage.administration.accounts");
      },
      abortAndGoBack: function () {
        this.set("firstname", "");
        this.set("lastname", "");
        this.set("email", "");
        this.set("phone", "");
        this.set("isAdmin", false);
        this.set("isManager", false);
        this.set("isRemote", false);
        this.set("isLocal", false);
        this.set("isCustomer", false);
        this.set("selectedDefaultRole", "");
        this.set("latitude", "");
        this.set("longitude", "");
        this.set("selCustomer", null);
        return this.transitionToRoute("weManage.administration.accounts");
      }
    }
  });

  _exports.default = _default;
});