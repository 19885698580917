define("managing-tool-frontend/components/pagination-addin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i4Vg7xeR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"morePages\"]]],null,{\"statements\":[[0,\"  \"],[7,\"nav\"],[12,\"aria-label\",[27,\"t\",[\"pagination\"],null]],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"pagination justify-content-center\"],[9],[0,\"\\n      \"],[7,\"li\"],[12,\"class\",[28,[\"page-item \",[27,\"if\",[[23,[\"possiblePrevPage\"]],\"\",\"disabled\"],null]]]],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"page-link\"],[11,\"href\",\"#\"],[12,\"aria-label\",[27,\"t\",[\"previous\"],null]],[9],[0,\"\\n          \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[1,[27,\"fa-icon\",[\"angle-double-left\"],null],false],[10],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"changePage\",0]],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"page-item\"],[9],[7,\"a\"],[11,\"class\",\"page-link\"],[11,\"href\",\"#\"],[9],[1,[21,\"currentPage\"],false],[0,\"/\"],[1,[21,\"pages\"],false],[10],[10],[0,\"\\n      \"],[7,\"li\"],[12,\"class\",[28,[\"page-item \",[27,\"if\",[[23,[\"possibleNextPage\"]],\"\",\"disabled\"],null]]]],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"page-link\"],[11,\"href\",\"#\"],[12,\"aria-label\",[27,\"t\",[\"next\"],null]],[9],[0,\"\\n          \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[1,[27,\"fa-icon\",[\"angle-double-right\"],null],false],[10],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"changePage\",1]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/components/pagination-addin/template.hbs"
    }
  });

  _exports.default = _default;
});