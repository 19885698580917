define("managing-tool-frontend/we-manage/customer/incident-create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      this.controllerFor("weManage.customer").send("switchBreadcrumb", 2);
    },

    renderTemplate: function () {
      this.render("weManage/customer/incidentCreate", {
        into: "weManage/customer",
        outlet: "handleIncident"
      });
    }
  });

  _exports.default = _default;
});