define("managing-tool-frontend/we-manage/service-processing/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      tasksOffset: {
        refreshModel: true
      }
    },
    session: Ember.inject.service(),

    activate() {
      this.controllerFor("weManage.serviceProcessing").send("switchBreadcrumb", 1);
    },

    model(params) {
      let thisUser = this.get("session").get("data.user");
      return this.store.query("task", {
        assignedUserID: thisUser.id,
        states: "1!2!5!6",
        limit: 6,
        offset: params.tasksManagementOffset
      }).then(tasks => {
        return {
          tasks: tasks,
          tasksM: tasks.get("meta")
        };
      });
    },

    setupController(controller, {
      tasks,
      tasksM
    }) {
      this._super(controller, {
        tasks,
        tasksM
      });

      controller.set("tasksTotalPages", Math.ceil(tasksM.count / 6));
      let allEvents = [];
      tasks.forEach(function (task) {
        let taskPriority;

        switch (task.get("priority")) {
          case 2:
            taskPriority = "#C9302C";
            break;

          case 3:
            taskPriority = "#FF00FF";
            break;

          default:
            taskPriority = "#5476A4";
        }

        let thisEvent = {
          allDay: true,
          start: task.get("appointment"),
          title: task.get("subject"),
          id: task.get("id"),
          backgroundColor: "#5476A4",
          borderColor: taskPriority,
          textColor: "#FFFFFF",
          className: "calendarTask"
        };
        allEvents.pushObject(thisEvent);
      });
      controller.set("events", allEvents);
    },

    renderTemplate() {
      this.render("navigation/serviceProcessing", {
        into: "weManage",
        outlet: "navibar"
      });
      this.render("weManage/serviceProcessing", {
        into: "weManage",
        outlet: "maincontent"
      });
    }

  });

  _exports.default = _default;
});