define("managing-tool-frontend/we-manage/administration/accounts/account/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function () {
      this.render("weManage/administration/accounts/account/edit", {
        into: "weManage/administration/accounts"
      });
    },
    model: function () {
      return this.modelFor("weManage.administration.accounts.account");
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      let roles = model.get("roles");

      if ((roles & 1) !== 0) {
        controller.set("isAdmin", true);
      }

      if ((roles & 2) !== 0) {
        controller.set("isManager", true);
      }

      if ((roles & 4) !== 0) {
        controller.set("isRemote", true);
      }

      if ((roles & 8) !== 0) {
        controller.set("isLocal", true);
      }

      if ((roles & 16) !== 0) {
        controller.set("isCustomer", true);
      }
    }
  });

  _exports.default = _default;
});