define("managing-tool-frontend/we-manage/administration/customers/customer/locations/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let location = this.modelFor("weManage.administration.customers.customer");
      controller.set("location", location);
    },

    renderTemplate() {
      this.render("weManage/administration/customers/customer/locations/new", {
        into: "weManage/administration/customers",
        outlet: "newCustomerItems"
      });
    }

  });

  _exports.default = _default;
});