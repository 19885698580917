define("managing-tool-frontend/we-manage/service-management/task-management/task/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    serviceManagement: Ember.inject.controller('weManage.serviceManagement'),
    reassign: false,
    commentsShown: true,
    selTechnician: null,
    allTechnicians: Ember.computed("user", function () {
      let techList = [];
      this.store.query("user", {
        limit: 18,
        offset: 0
      }).then(users => {
        users.forEach(function (user) {
          if ((user.roles & 4) !== 0) {
            techList.addObject(user);
          } else if ((user.roles & 8) !== 0) {
            techList.addObject(user);
          }
        });
      });
      return techList;
    }),
    isCloseable: Ember.computed("model.state", function () {
      let state = this.get("model").get("state");

      if (state === 3 || state === 7) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      cancelButton: function () {
        (0, _jquery.default)('#taskCancelButton').tooltip('hide');
        let task = this.get("model");
        task.set("state", 3);
        task.save().then(function (savedTask) {
          savedTask.hasMany("taskLogs").reload();
        });
      },
      editButton: function () {
        (0, _jquery.default)('#taskEditButton').tooltip('hide');
        return this.transitionToRoute("weManage.serviceManagement.taskManagement.task.edit");
      },
      closeButton: function () {
        (0, _jquery.default)('#taskCloseButton').tooltip('hide');
        let task = this.get("model");
        task.set("state", 7);
        task.save().then(function (savedTask) {
          savedTask.hasMany("taskLogs").reload();
        });
      },
      taskReassignButton: function () {
        (0, _jquery.default)('#taskReassignButton').tooltip('hide');
        this.set("reassign", true);
      },
      backButton: function () {
        this.set("reassign", false);
      },
      saveButton: function () {
        let selTechnician = this.get("selTechnician");

        if (selTechnician === null) {
          selTechnician = this.get("allTechnicians").get("firstObject");
        }

        let task = this.get("model");
        task.set("state", 1);
        task.set("assignedUser", selTechnician);
        task.save().then(function (savedTask) {
          savedTask.hasMany("taskLogs").reload();
        });
        this.set("selTechnician", null);
        this.set("reassign", false);
        let incident = this.get("incident");
        incident.set("state", 5);
        incident.save().then(function (savedIncident) {
          savedIncident.hasMany("incidentLogs").reload();
        });
      },
      addCommentButton: function () {
        let type = 1;
        let commentRadios = document.getElementsByName("commentRadios");

        for (let i = 0, length = commentRadios.length; i < length; i++) {
          if (commentRadios[i].checked) {
            type = commentRadios[i].value;
            break;
          }
        }

        let newComment = this.store.createRecord("incidentComment", {
          text: this.get("commentAdded"),
          type: type,
          incident: this.get("incident")
        });
        newComment.save();
        this.set("commentAdded", "");
      },
      toggleCommentsShow: function () {
        let commentsShown = this.get('commentsShown');
        this.set('commentsShown', !commentsShown);
      },
      gotoThisIncident: function (thatIncident) {
        return this.transitionToRoute("weManage.serviceManagement.incidentManagement.incident", thatIncident, {
          queryParams: {
            incidentsCurrentOffset: 0
          }
        });
      }
    }
  });

  _exports.default = _default;
});