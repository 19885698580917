define("managing-tool-frontend/we-manage/administration/machinetypes/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function () {
      this.render("weManage/administration/machinetypes/new", {
        into: "weManage/administration/machinetypes",
        outlet: "newMachineTypes"
      });
    }
  });

  _exports.default = _default;
});