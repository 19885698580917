define("managing-tool-frontend/we-manage/service-management/task-management/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.store.query("user", {
        limit: 18,
        offset: 0
      }).then(users => {
        return {
          users: users,
          usersM: users.get("meta"),
          task: this.store.createRecord("task", {
            state: 1
          })
        };
      });
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      let usedIncident = this.modelFor("weManage.serviceManagement.incidentManagement.incident");
      controller.set("incident", usedIncident);
    },

    renderTemplate() {
      this.render("weManage/serviceManagement/taskManagement/new", {
        into: "weManage/serviceManagement/taskManagement",
        outlet: "handleTask"
      });
    }

  });

  _exports.default = _default;
});