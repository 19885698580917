define("managing-tool-frontend/we-manage/administration/accounts/account/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    customerRole: null,
    customer: Ember.computed("customerRole", function () {
      if ((this.get("customerRole") & 16) !== 0) {
        return true;
      }
    }),
    actions: {
      deleteUser: function (user) {
        this.transitionToRoute("weManage.administration.accounts");
        this.store.findRecord("user", user.id).then(function (nobody) {
          nobody.destroyRecord();
        });
      },
      editUser: function () {
        this.transitionToRoute("weManage.administration.accounts.account.edit");
      }
    }
  });

  _exports.default = _default;
});