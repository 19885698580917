define("managing-tool-frontend/we-manage/service-processing/task-processing/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    taskComment: null,
    actions: {
      submitComment: function () {
        let type = 1;
        let commentRadios = document.getElementsByName("commentRadios");

        for (let i = 0, length = commentRadios.length; i < length; i++) {
          if (commentRadios[i].checked) {
            type = commentRadios[i].value;
            break;
          }
        }

        let commentContent = this.get("taskComment");
        let incident = this.get("incident");
        let newComment = this.store.createRecord("incidentComment", {
          text: commentContent,
          type: type,
          incident: incident
        });
        newComment.save();
        this.set("taskComment", "");
      },
      pauseButton: function () {
        let that = this;
        let task = this.get("taskActive");
        task.set("state", 6);
        task.save().then(function (savedTask) {
          savedTask.hasMany("taskLogs").reload();
          that.transitionToRoute("weManage.serviceProcessing.taskManagement");
        });
      },
      abortButton: function () {
        (0, _jquery.default)("#abortTaskModal").modal("show");
      },
      reallyAbort: function () {
        let that = this;
        let task = this.get("taskActive");
        task.set("state", 3);
        task.save().then(function (savedTask) {
          savedTask.hasMany("taskLogs").reload();
          savedTask.get("incident").then(function (incident) {
            incident.set("state", 6);
            incident.save().then(function (savedIncident) {
              savedIncident.hasMany("incidentLogs").reload();
              that.transitionToRoute("weManage.serviceProcessing.taskManagement");
            });
          });
        });
      },
      finishButton: function () {
        let task = this.get("model");
        this.transitionToRoute("weManage.serviceProcessing.taskManagement.task.conclusion", task);
      }
    }
  });

  _exports.default = _default;
});