define("managing-tool-frontend/we-manage/administration/customers/customer/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      return this.store.findRecord("customer", params.customer_id);
    },
    afterModel: function (model) {
      this.modelFor("weManage.administration.customers").customers.forEach(function (customer) {
        if (customer.id !== "#") {
          customer.set("selected", false);
        }
      });
      model.set("selected", true);
    },
    renderTemplate: function () {
      this.render("weManage/administration/customers/customer", {
        into: "weManage/administration/customers",
        outlet: "customerDetails"
      });
    }
  });

  _exports.default = _default;
});