define("managing-tool-frontend/we-manage/service-processing/task-processing/route", ["exports", "jquery", "managing-tool-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global  WeREPORT */
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    activate() {
      if (WeREPORT) {
        window.location.assign("https://" + _environment.default.reportHome);
      } else {
        this.controllerFor("weManage.serviceProcessing").send("switchBreadcrumb", 2);
        (0, _jquery.default)(function () {
          (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();
        });
      }
    },

    deactivate() {
      (0, _jquery.default)('[data-toggle="tooltip"]').tooltip("hide");
    },

    model() {
      let thisUser = this.get("session").get("data.user");
      return this.store.query("task", {
        assignedUserID: thisUser.id,
        states: "5",
        limit: 10,
        offset: 0
      }).then(function (allTasks) {
        if (allTasks.length > 1) {
          alert("Task Error: more than one active task!");
        }

        return allTasks.get("firstObject");
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let that = this;

      if (model) {
        console.log("setupController model: ", model);
        controller.set("taskActive", model);
        let incident = model.get("incident");
        controller.set("incident", incident);
        this.transitionTo("weManage.serviceProcessing.taskProcessing.taskDetails");
      } else {
        console.log("setupController no model");
        controller.set("taskActive", null);
        controller.set("incident", null);
        Ember.run.later(function () {
          that.transitionTo("weManage.serviceProcessing.taskManagement");
        }, 2500);
      }
    },

    renderTemplate() {
      this.render("weManage/serviceProcessing/taskProcessing", {
        into: "weManage",
        outlet: "maincontent"
      });
    }

  });

  _exports.default = _default;
});