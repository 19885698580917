define("managing-tool-frontend/components/select-object/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    allObjects: null,
    selectedObject: null,

    init() {
      this._super(...arguments);

      let allObjects = this.get("allObjects");

      if (!allObjects) {
        this.set("allObjects", []);
      }
    },

    actions: {
      selectObject(value) {
        let selectedIndex = value.selectedIndex;
        let selectedObject = null;
        let allObjects = this.get("allObjects");
        allObjects.forEach(function (object) {
          if (selectedIndex === 0) {
            selectedObject = object;
          }

          selectedIndex--;
        });
        this.set("selectedObject", selectedObject);
      }

    }
  });

  _exports.default = _default;
});