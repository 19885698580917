define("managing-tool-frontend/we-manage/administration/machinetypes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lx+1Efg/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-header text-white bg-primary\"],[9],[0,\"\\n    \"],[1,[27,\"fa-icon\",[\"certificate\"],null],false],[0,\" \"],[1,[27,\"t\",[\"machine.newType\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"table\"],[9],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"kindOfCategory\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"placeholder\",\"value\"],[\"Type\",[23,[\"name\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"categoryName\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"placeholder\",\"value\"],[\"Category\",[23,[\"category\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-footer\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-outline-warning\"],[9],[1,[27,\"fa-icon\",[\"times-circle\"],[[\"prefix\"],[\"far\"]]],false],[0,\" \"],[1,[27,\"t\",[\"abort\"],null],false],[3,\"action\",[[22,0,[]],\"abortButton\"]],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-outline-success float-right\"],[9],[1,[27,\"fa-icon\",[\"check-circle\"],[[\"prefix\"],[\"far\"]]],false],[0,\" \"],[1,[27,\"t\",[\"save\"],null],false],[3,\"action\",[[22,0,[]],\"saveAndGoBack\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/we-manage/administration/machinetypes/new/template.hbs"
    }
  });

  _exports.default = _default;
});