define("managing-tool-frontend/templates/navigation/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dSdFlq8/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"OVActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"calendar-alt\"],null],false],[0,\" \"],[1,[27,\"t\",[\"overview\"],null],false],[3,\"action\",[[22,0,[]],\"loadOverview\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"CRActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"fire\"],null],false],[0,\" \"],[1,[27,\"t\",[\"incident.create\"],null],false],[3,\"action\",[[22,0,[]],\"loadCreate\"]],[10],[10],[0,\"\\n\"],[7,\"li\"],[12,\"class\",[28,[\"nav-item \",[27,\"if\",[[23,[\"HIActive\"]],\"active\"],null]]]],[9],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"nav-link\"],[9],[1,[27,\"fa-icon\",[\"hourglass-end\"],null],false],[0,\" \"],[1,[27,\"t\",[\"history\"],null],false],[3,\"action\",[[22,0,[]],\"loadHistory\"]],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/templates/navigation/customer.hbs"
    }
  });

  _exports.default = _default;
});