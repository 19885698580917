define("managing-tool-frontend/we-manage/service-management/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      incidentsOffset: {
        refreshModel: true
      },
      tasksOffset: {
        refreshModel: true
      }
    },

    activate() {
      this.controllerFor("weManage/serviceManagement").send("switchBreadcrumb", 1);
    },

    model(params) {
      return this.store.query("task", {
        states: "1!2!5!6",
        limit: 6,
        offset: params.tasksOffset
      }).then(tasks => {
        return this.store.query("incident", {
          states: "1!2!5!6!7!8!9!10!11!12",
          limit: 6,
          offset: params.incidentsOffset
        }).then(incidents => {
          return {
            tasks: tasks,
            tasksM: tasks.get("meta"),
            incidents: incidents,
            incidentsM: incidents.get("meta")
          };
        });
      });
    },

    setupController(controller, {
      tasks,
      tasksM,
      incidents,
      incidentsM
    }) {
      this._super(controller, {
        tasks,
        tasksM,
        incidents,
        incidentsM
      });

      let allEvents = [];
      tasks.forEach(function (task) {
        let taskPriority;

        switch (task.get("priority")) {
          case "2":
            taskPriority = "#C9302C";
            break;

          case "3":
            taskPriority = "#FF00FF";
            break;

          default:
            taskPriority = "#5476A4";
        }

        let thisEvent = {
          allDay: true,
          start: task.get("appointment"),
          title: task.get("subject"),
          id: task.get("id"),
          backgroundColor: "#5476A4",
          borderColor: taskPriority,
          textColor: "#FFFFFF",
          className: "calendarTask",
          eventType: "task"
        };
        allEvents.pushObject(thisEvent);
      });
      incidents.forEach(function (incident) {
        let incidentPriority;

        switch (incident.get("priority")) {
          case "2":
            incidentPriority = "#C9302C";
            break;

          case "3":
            incidentPriority = "#FF00FF";
            break;

          default:
            incidentPriority = "#EC971F";
        }

        let thisIncident = {
          allDay: true,
          start: incident.get("created"),
          title: incident.get("subject"),
          id: incident.get("id"),
          backgroundColor: "#EC971F",
          borderColor: incidentPriority,
          textColor: "#000000",
          className: "calendarIncident",
          eventType: "incident"
        };
        allEvents.pushObject(thisIncident);
      });
      controller.set("events", allEvents);
      controller.set("incidentsTotalPages", Math.ceil(incidentsM.count / 6));
      controller.set("tasksTotalPages", Math.ceil(tasksM.count / 6));
    },

    renderTemplate() {
      this.render("navigation/serviceManagement", {
        into: "weManage",
        outlet: "navibar"
      });
      this.render("weManage/serviceManagement", {
        into: "weManage",
        outlet: "maincontent"
      });
    },

    actions: {
      didTransition() {
        this.controllerFor("weManage/serviceManagement").send("generateChartsAction");
        return true; // Bubble the didTransition event
      }

    }
  });

  _exports.default = _default;
});