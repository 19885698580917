define("managing-tool-frontend/we-manage/service-processing/task-management/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      tasksManagementOffset: {
        refreshModel: true
      }
    },
    session: Ember.inject.service(),

    activate() {
      this.controllerFor("weManage.serviceProcessing").send("switchBreadcrumb", 3);
    },

    renderTemplate() {
      this.render("weManage/serviceProcessing/taskManagement", {
        into: "weManage",
        outlet: "maincontent"
      });
    },

    model(params) {
      let thisUser = this.get("session").get('data.user');
      return this.store.query('task', {
        assignedUserID: thisUser.id,
        states: '1!2!5!6',
        limit: 6,
        offset: params.tasksManagementOffset
      }).then(tasks => {
        return {
          tasks: tasks,
          tasksM: tasks.get('meta')
        };
      });
    },

    afterModel(model) {
      model.tasks.forEach(function (task) {
        task.set("selected", false);
      });
    },

    setupController(controller, {
      tasks,
      tasksM
    }) {
      this._super(controller, {
        tasks,
        tasksM
      });

      controller.set("tasksTotalPages", Math.ceil(tasksM.count / 6));
    },

    redirect({
      tasks
    }, transition) {
      if (transition.targetName === this.routeName + ".index") {
        if (tasks != null) {
          if (tasks.get("length") > 0) {
            this.transitionTo("weManage.serviceProcessing.taskManagement.task", tasks.get("firstObject"));
          }
        }
      }
    },

    actions: {
      loadTask: function (task) {
        return this.transitionTo("weManage.serviceProcessing.taskManagement.task", task);
      }
    }
  });

  _exports.default = _default;
});