define("managing-tool-frontend/we-manage/administration/customers/customer/machines/machine/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function () {
      this.render("weManage/administration/customers/customer/machines/machine", {
        into: "weManage/administration/customers",
        outlet: "itemsDetails"
      });
    },
    model: function (params) {
      return this.store.findRecord("machine", params.machine_id);
    },
    afterModel: function (model) {
      let machines = this.modelFor("weManage.administration.customers.customer.machines");
      machines.forEach(function (machine) {
        machine.set("selected", false);
      });
      model.set("selected", true);
    }
  });

  _exports.default = _default;
});