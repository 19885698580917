define("managing-tool-frontend/components/taskstate-line/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U5SGwsLC",
    "block": "{\"symbols\":[\"thisLog\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"timeline\"],[11,\"id\",\"taskTimeline\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"taskLog\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"li complete\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"status\"],[9],[0,\"\\n        \"],[7,\"h4\"],[9],[0,\" \"],[1,[27,\"task-state\",[[22,1,[\"status\"]]],null],false],[0,\" \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"timestamp\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"author\"],[9],[7,\"small\"],[9],[1,[22,1,[\"user\"]],false],[10],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"date\"],[9],[7,\"small\"],[9],[1,[27,\"timestamp-readable\",[[22,1,[\"created\"]]],null],false],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/components/taskstate-line/template.hbs"
    }
  });

  _exports.default = _default;
});