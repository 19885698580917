define("managing-tool-frontend/we-manage/administration/customers/customer/machines/machine/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    name: null,
    serialNumber: null,
    allTypes: Ember.computed("machineType", function () {
      return this.store.findAll("machineType");
    }),
    selectedType: null,
    actions: {
      saveAndGoBack: function () {
        let machine = this.get("model");
        let selectedType = this.get("selectedType");

        if (selectedType !== null) {
          machine.set("machineType", selectedType);
          this.set("selectedType", null);
        }

        machine.save();
        this.set("selectedType", null);
        return this.transitionToRoute("weManage.administration.customers.customer.machines.machine", machine);
      },
      abortAndGoBack: function () {
        let machine = this.get("model");
        machine.rollbackAttributes();
        this.set("selectedType", null);
        return this.transitionToRoute("weManage.administration.customers.customer.machines.machine", machine);
      }
    }
  });

  _exports.default = _default;
});