define("managing-tool-frontend/we-manage/service-management/history/tasks/task/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3AOAo0bv",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-header text-white bg-primary\"],[9],[0,\"\\n    \"],[1,[27,\"fa-icon\",[\"wrench\"],null],false],[0,\" \"],[1,[27,\"t\",[\"task.task\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n    \"],[7,\"h3\"],[9],[1,[23,[\"model\",\"subject\"]],false],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[7,\"h4\"],[9],[1,[23,[\"model\",\"description\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[23,[\"model\",\"fDate\"]],false],[0,\" - \"],[1,[23,[\"model\",\"fTime\"]],false],[0,\" - \"],[1,[23,[\"model\",\"creator\",\"name\"]],false],[7,\"br\"],[9],[10],[0,\"\\n      \"],[1,[27,\"t\",[\"priority\"],null],false],[0,\": \"],[1,[27,\"priority-readable\",[[23,[\"model\",\"priority\"]]],null],false],[0,\" - \"],[1,[27,\"t\",[\"appointment\"],null],false],[0,\":\"],[1,[23,[\"model\",\"fDDate\"]],false],[0,\" - \"],[1,[23,[\"model\",\"fDTime\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[7,\"h3\"],[9],[1,[27,\"t\",[\"comments\"],null],false],[0,\":\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"incident\",\"incidentComments\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"model\",\"incident\",\"incidentComments\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"component\",[\"incident-comment\"],[[\"comment\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"noComments\"],null],false],[0,\".\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/we-manage/service-management/history/tasks/task/template.hbs"
    }
  });

  _exports.default = _default;
});