define("managing-tool-frontend/we-manage/service-management/task-management/task/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.allPriority = ["NORMAL", "HIGH", "CRITICAL"];
    },

    selPriority: null,
    selTechnician: null,
    allTechnicians: Ember.computed("user", function () {
      let techList = [];
      this.store.query("user", {
        limit: 18,
        offset: 0
      }).then(users => {
        users.forEach(function (user) {
          if ((user.roles & 4) !== 0) {
            techList.addObject(user);
          } else if ((user.roles & 8) !== 0) {
            techList.addObject(user);
          }
        });
      });
      return techList;
    }),
    actions: {
      editButton: function () {
        let task = this.get("model");
        let chosenDate = this.get("chosenDate");

        if (chosenDate) {
          var jsonAppointment = chosenDate.toJSON();
        }

        let thisUser = this.get("session").get('data.user');
        let receivedUser = this.store.findRecord('user', thisUser.id);
        let selPriority = this.get("selPriority");

        if (selPriority === "HIGH") {
          this.set("selPriority", 2);
        } else if (selPriority === "CRITICAL") {
          this.set("selPriority", 3);
        } else {
          this.set("selPriority", 1);
        }

        task.set("state", 1);
        task.set("priority", selPriority);

        if (jsonAppointment && jsonAppointment !== task.get("appointment")) {
          task.set("appointment", jsonAppointment);
        }

        task.set("creator", receivedUser);
        let selTechnician = this.get("selTechnician");

        if (selTechnician === null) {
          selTechnician = this.get("allTechnicians").get("firstObject");
        }

        task.set("assignedUser", selTechnician);
        task.get("incident").then(thisIncident => {
          thisIncident.set("state", 5);
          thisIncident.save();
        });
        task.save();
        this.set("subject", "");
        this.set("description", "");
        return this.transitionToRoute("weManage.serviceManagement.taskManagement.task", task);
      },
      abortButton: function () {
        let task = this.get("model");
        this.set("subject", "");
        this.set("description", "");
        return this.transitionToRoute("weManage.serviceManagement.taskManagement.task", task);
      }
    }
  });

  _exports.default = _default;
});