define("managing-tool-frontend/we-manage/service-management/incident-management/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['incidentsCurrentOffset'],
    incidentsCurrentPage: 1,
    incidentsTotalPages: 0,
    incidentsCurrentOffset: 0,
    actions: {
      changePage(whatWhere) {
        if (whatWhere < 2) {
          let page = this.get('incidentsCurrentPage');

          if (whatWhere == 1) {
            this.set('incidentsCurrentPage', page + 1);
            this.set('incidentsCurrentOffset', page * 6);
          } else {
            this.set('incidentsCurrentPage', page - 1);
            this.set('incidentsCurrentOffset', (page - 2) * 6);
          }
        }
      },

      reloadModel() {
        this.send("refreshModel");
      }

    }
  });

  _exports.default = _default;
});