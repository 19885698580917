define("managing-tool-frontend/we-manage/administration/customers/customer/machines/machine/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ru0mkJLc",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"machinedetail-panel\",null,[[\"machine\",\"edit\",\"deleteMachine\",\"editMachine\"],[[23,[\"model\"]],true,[27,\"action\",[[22,0,[]],\"deleteMachine\"],null],[27,\"action\",[[22,0,[]],\"editMachine\"],null]]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/we-manage/administration/customers/customer/machines/machine/template.hbs"
    }
  });

  _exports.default = _default;
});