define("managing-tool-frontend/helpers/timestamp-readable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timestampReadable = timestampReadable;
  _exports.default = void 0;

  function timestampReadable(params) {
    let reDate = new Date(params);
    let localDate = reDate.toLocaleDateString();
    let localTime = reDate.toLocaleTimeString();
    return localTime + " - " + localDate;
  }

  var _default = Ember.Helper.helper(timestampReadable);

  _exports.default = _default;
});