define("managing-tool-frontend/serializers/incident", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    normalizeQueryResponse(store, ModelClass, payload, id, requestName) {
      let normalized = this._super(store, ModelClass, payload.incidents, id, requestName);

      normalized.meta = payload.meta;
      return normalized;
    },

    serialize: function (record, options) {
      let json = this._super(record, options);

      json.creator = parseInt(json.creator);
      json.machine = parseInt(json.machine);
      json.assignedUser = parseInt(json.assignedUser);
      json.incident = parseInt(json.incident);
      return json;
    }
  });

  _exports.default = _default;
});