define("managing-tool-frontend/we-manage/administration/machinetypes/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      this.controllerFor("weManage.administration").send("switchBreadcrumb", 3);
    },

    renderTemplate: function () {
      this.render("weManage/administration/machinetypes", {
        into: "weManage",
        outlet: "maincontent"
      });
    },
    model: function () {
      return this.store.findAll("machineType");
    },
    afterModel: function (model) {
      model.forEach(function (type) {
        type.set("selected", false);
      });
    },
    actions: {
      newMType: function () {
        return this.transitionTo("weManage.administration.machinetypes.new");
      },

      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});