define("managing-tool-frontend/we-manage/service-processing/task-history/task/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iAkiTzTn",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-header text-white bg-primary\"],[9],[0,\"\\n    \"],[1,[27,\"fa-icon\",[\"wrench\"],null],false],[0,\" \"],[1,[27,\"t\",[\"task.history\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n    \"],[7,\"h3\"],[9],[1,[23,[\"model\",\"subject\"]],false],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[7,\"h4\"],[9],[1,[23,[\"model\",\"description\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"t\",[\"created\"],null],false],[0,\": \"],[1,[23,[\"model\",\"fDate\"]],false],[0,\" - \"],[1,[23,[\"model\",\"fTime\"]],false],[0,\" - \"],[1,[23,[\"model\",\"creator\",\"name\"]],false],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[7,\"h3\"],[9],[1,[27,\"t\",[\"comments\"],null],false],[0,\":\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"incident\",\"incidentComments\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"component\",[\"incident-comment\"],[[\"comment\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/we-manage/service-processing/task-history/task/template.hbs"
    }
  });

  _exports.default = _default;
});