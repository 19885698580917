define("managing-tool-frontend/we-manage/customer/incident-detail/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isConfirmable: Ember.computed("state", function () {
      return this.get("state") !== 2;
    }),
    isInvalid: Ember.computed("state", function () {
      return this.get("state") !== 2;
    }),
    isAcceptable: Ember.computed("state", function () {
      return this.get("state") !== 10;
    }),
    isRejectable: Ember.computed("state", function () {
      return this.get("state") !== 10;
    }),
    isInterruptable: Ember.computed("state", function () {
      let state = this.get("state");
      let canDo = false;

      if (state === 3 || state === 13 || state === 14) {
        canDo = true;
      }

      return canDo;
    }),
    actions: {
      confirmButton: function () {
        (0, _jquery.default)('#incidentConfirmButton').tooltip('hide');
        let incident = this.get("model");
        incident.set("state", 1);
        incident.save().then(function (savedIncident) {
          savedIncident.hasMany("incidentLogs").reload();
        });
      },
      invalidButton: function () {
        (0, _jquery.default)('#incidentInvalidButton').tooltip('hide');
        let incident = this.get("model");
        incident.set("state", 4);
        incident.save().then(function (savedIncident) {
          savedIncident.hasMany("incidentLogs").reload();
        });
      },
      acceptButton: function () {
        (0, _jquery.default)('#incidentAcceptButton').tooltip('hide');
        let incident = this.get("model");
        incident.set("state", 11);
        incident.save().then(function (savedIncident) {
          savedIncident.hasMany("incidentLogs").reload();
        });
      },
      rejectButton: function () {
        (0, _jquery.default)('#incidentRejectButton').tooltip('hide');
        let incident = this.get("model");
        incident.set("state", 12);
        incident.save().then(function (savedIncident) {
          savedIncident.hasMany("incidentLogs").reload();
        });
      },
      interruptButton: function () {
        (0, _jquery.default)('#incidentInterruptButton').tooltip('hide');
        let incident = this.get("model");
        incident.set("state", 3);
        incident.save().then(function (savedIncident) {
          savedIncident.hasMany("incidentLogs").reload();
        });
      },
      addCommentButton: function () {
        let type = 1;
        let commentRadios = document.getElementsByName("commentRadios");

        for (let i = 0, length = commentRadios.length; i < length; i++) {
          if (commentRadios[i].checked) {
            type = commentRadios[i].value;
            break;
          }
        }

        let newComment = this.store.createRecord("incidentComment", {
          text: this.get("commentAdded"),
          type: type,
          incident: this.get("model")
        });
        newComment.save();
        this.set("commentAdded", "");
      }
    }
  });

  _exports.default = _default;
});