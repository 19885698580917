define("managing-tool-frontend/we-manage/service-management/incident-management/new/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      (0, _jquery.default)(function () {
        (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();
      });
    },

    renderTemplate: function () {
      this.render("weManage/serviceManagement/incidentManagement/new", {
        into: "weManage/serviceManagement/incidentManagement",
        outlet: "handleIncident"
      });
    },

    deactivate() {
      (0, _jquery.default)('[data-toggle="tooltip"]').tooltip('hide');
    }

  });

  _exports.default = _default;
});