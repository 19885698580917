define("managing-tool-frontend/models/machine", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    serialNumber: _emberData.default.attr(),
    year: _emberData.default.attr(),
    machineType: _emberData.default.belongsTo("machineType"),
    customer: _emberData.default.belongsTo("customer"),
    selected: false
  });

  _exports.default = _default;
});