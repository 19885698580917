define("managing-tool-frontend/helpers/comment-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.commentSymbol = commentSymbol;
  _exports.default = void 0;

  function commentSymbol(value) {
    let typeInt = parseInt(value);
    let symbol = "info-circle";

    if (typeInt === 2) {
      symbol = "question-circle";
    } else if (typeInt === 3) {
      symbol = "check-circle";
    } else if (typeInt === 4) {
      symbol = "exclamation-circle";
    }

    return symbol;
  }

  var _default = Ember.Helper.helper(commentSymbol);

  _exports.default = _default;
});