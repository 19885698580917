define("managing-tool-frontend/we-manage/administration/accounts/account/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    allDefaultRoles: Ember.computed("isAdmin", "isManager", "isRemote", "isLocal", "isCustomer", function () {
      let defaultRoleOut = [];

      if (this.get("isAdmin")) {
        defaultRoleOut.addObject(this.intl.t('admin'));
      }

      if (this.get("isManager")) {
        defaultRoleOut.addObject(this.intl.t('manager'));
      }

      if (this.get("isRemote")) {
        defaultRoleOut.addObject(this.intl.t('rTechnician'));
      }

      if (this.get("isLocal")) {
        defaultRoleOut.addObject(this.intl.t('lTechnician'));
      }

      if (this.get("isCustomer")) {
        defaultRoleOut.addObject(this.intl.t('customer.customer'));
      }

      return defaultRoleOut;
    }),
    selectedDefaultRole: null,
    multiRole: Ember.computed("isAdmin", "isManager", "isRemote", "isLocal", "isCustomer", function () {
      let roleCount = this.get("allDefaultRoles").length;
      return roleCount > 1;
    }),
    allCustomers: Ember.computed("customer", function () {
      return this.store.query("customer", {
        limit: 18,
        offset: 0
      });
    }),
    selCustomer: null,
    actions: {
      saveAndGoBack: function () {
        let selectedRole = 0;
        let defaultRole = 0;

        if (this.get("isCustomer")) {
          selectedRole += 16;
          defaultRole = 16;
        }

        if (this.get("isLocal")) {
          selectedRole += 8;
          defaultRole = 8;
        }

        if (this.get("isRemote")) {
          selectedRole += 4;
          defaultRole = 4;
        }

        if (this.get("isManager")) {
          selectedRole += 2;
          defaultRole = 2;
        }

        if (this.get("isAdmin")) {
          selectedRole += 1;
          defaultRole = 1;
        }

        let selectedDefaultRole = this.get("selectedDefaultRole");

        switch (selectedDefaultRole) {
          case "Admin":
            defaultRole = 1;
            break;

          case "Manager":
            defaultRole = 2;
            break;

          case "R. Technician":
            defaultRole = 4;
            break;

          case "L. Technician":
            defaultRole = 8;
            break;

          case "Customer":
            defaultRole = 16;
            break;
        }

        this.set("selectedDefaultRole", "");
        let selCustomer = this.get("selCustomer");

        if (selCustomer === null) {
          let allCustomers = this.get("allCustomers");
          selCustomer = allCustomers.get("firstObject");
        }

        let user = this.get("model");
        user.set("roles", selectedRole);
        user.set("defaultRole", defaultRole);
        user.set("customer", selCustomer);
        user.save();
        this.set("isAdmin", false);
        this.set("isManager", false);
        this.set("isRemote", false);
        this.set("isLocal", false);
        this.set("isCustomer", false);
        return this.transitionToRoute("weManage.administration.accounts.account", user);
      },
      abortAndGoBack: function () {
        let user = this.get("model");
        user.rollbackAttributes();
        this.set("isAdmin", false);
        this.set("isManager", false);
        this.set("isRemote", false);
        this.set("isLocal", false);
        this.set("isCustomer", false);
        return this.transitionToRoute("weManage.administration.accounts.account", user);
      }
    }
  });

  _exports.default = _default;
});