define("managing-tool-frontend/components/incident-comment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fType: Ember.computed("comment.type", "comment", function () {
      let type = this.get("comment").get("type");
      let typeInt = parseInt(type);

      switch (typeInt) {
        case 1:
          return "info-circle";

        case 2:
          return "question-circle";

        case 3:
          return "check-circle";

        case 4:
          return "exclamation-circle";

        default:
          return "info-circle";
      }
    })
  });

  _exports.default = _default;
});