define("managing-tool-frontend/we-manage/service-processing/task-history/task/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      return this.store.findRecord("task", params.task_id);
    },
    afterModel: function (model) {
      this.modelFor("weManage.serviceProcessing.taskHistory").tasks.forEach(function (task) {
        task.set("selected", false);
      });
      model.set("selected", true);
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.store.findRecord("task", model.get("id")).then(function (task) {
        task.get("incident").then(function (incident) {
          controller.set("incident", incident);
          incident.get("machine").then(function (machine) {
            controller.set("machine", machine);
            machine.get("customer").then(function (customer) {
              controller.set("customer", customer);
            });
          });
        });
      });
    },
    renderTemplate: function () {
      this.render("weManage/serviceProcessing/taskHistory/task", {
        into: "weManage/serviceProcessing/taskHistory",
        outlet: "handleTask"
      });
      this.render("incidentdetails", {
        into: "weManage/serviceProcessing/taskHistory",
        outlet: "taskAlpha"
      });
      this.render("machinedetails", {
        into: "weManage/serviceProcessing/taskHistory",
        outlet: "taskBeta"
      });
      this.render("customerdetails", {
        into: "weManage/serviceProcessing/taskHistory",
        outlet: "taskGamma"
      });
    }
  });

  _exports.default = _default;
});