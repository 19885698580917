define("managing-tool-frontend/router", ["exports", "managing-tool-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("login");
    this.route("reset");
    this.route("weManage", function () {
      this.route("customer", function () {
        this.route("incidentCreate");
        this.route('history', function () {
          this.route('incident', {
            path: ":incident_id"
          });
        });
        this.route("incidentDetail", {
          path: ":incident_id"
        });
      });
      this.route("administration", function () {
        this.route("accounts", function () {
          this.route("new");
          this.route("account", {
            path: ":account_id"
          }, function () {
            this.route("edit");
          });
        });
        this.route("customers", function () {
          this.route("new");
          this.route("customer", {
            path: ":customer_id"
          }, function () {
            this.route("edit");
            this.route("machines", function () {
              this.route("new");
              this.route("machine", {
                path: ":machine_id"
              }, function () {
                this.route("edit");
              });
            });
            this.route("locations", function () {
              this.route("location", {
                path: ":location_id"
              }, function () {
                this.route("edit");
              });
              this.route("new");
            });
          });
        });
        this.route("machinetypes", function () {
          this.route("new");
        });
      });
      this.route("serviceManagement", function () {
        this.route("taskManagement", function () {
          this.route("new");
          this.route("task", {
            path: ":task_id"
          }, function () {
            this.route("edit");
          });
        });
        this.route("incidentManagement", function () {
          this.route("new");
          this.route("incident", {
            path: ":incident_id"
          }, function () {
            this.route("edit");
          });
        });
        this.route("history", function () {
          this.route("tasks", function () {
            this.route("task", {
              path: ":task_id"
            });
          });
          this.route("incidents", function () {
            this.route("incident", {
              path: ":incident_id"
            });
          });
        });
        this.route("overviewUser", function () {
          this.route("user", {
            path: ":user_id"
          });
        });
      });
      this.route("serviceProcessing", function () {
        this.route("taskManagement", function () {
          this.route("task", {
            path: ":task_id"
          }, function () {
            this.route("conclusion");
          });
        });
        this.route("taskProcessing", function () {
          this.route("taskDetails");
        });
        this.route("taskHistory", function () {
          this.route("task", {
            path: ":task_id"
          });
        });
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});