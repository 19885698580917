define("managing-tool-frontend/components/select-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z2gPl6bX",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"select\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"content\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,1,[]]],[12,\"selected\",[27,\"is-eq\",[[22,1,[]],[23,[\"selectedValue\"]]],null]],[9],[0,\"\\n      \"],[1,[22,1,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[3,\"action\",[[22,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/components/select-array/template.hbs"
    }
  });

  _exports.default = _default;
});