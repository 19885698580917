define("managing-tool-frontend/components/machinedetail-panel/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editable: Ember.computed("edit", function () {
      return this.edit;
    }),
    actions: {
      toggleMachinePanel: function () {
        let displayNow = document.getElementById("MachineDetail");

        if (displayNow.style.display === "none") {
          displayNow.style.display = "table";
        } else {
          displayNow.style.display = "none";
        }
      },
      deleteMachine: function (machine) {
        (0, _jquery.default)("#deleteMachineModal").modal("hide");
        this.deleteMachine(machine);
      },
      editMachine: function () {
        this.editMachine();
      }
    }
  });

  _exports.default = _default;
});