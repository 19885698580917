define("managing-tool-frontend/initializers/ember-cli-conditional-compile-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const initializer = {
    name: 'ember-cli-conditional-compile-features',
    initialize: function () {}
  };
  const feature_flags = {"WeINSPECT":false,"WeMONITOR":false,"WeREPORT":false,"WeGLASS":false};
  Object.keys(feature_flags).map(function (flag) {
    window[flag] = feature_flags[flag];
  });
  var _default = initializer;
  _exports.default = _default;
});