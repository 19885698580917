define("managing-tool-frontend/templates/customerdetails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gf57wsMB",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[\"customerinfo-panel\"],[[\"customer\",\"edit\"],[[23,[\"customer\"]],false]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/templates/customerdetails.hbs"
    }
  });

  _exports.default = _default;
});