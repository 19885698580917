define("managing-tool-frontend/components/select-object/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EPp5pqGK",
    "block": "{\"symbols\":[\"singleObject\"],\"statements\":[[7,\"select\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"selectObject\"],[[\"value\"],[\"target\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"allObjects\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,1,[]]],[12,\"selected\",[27,\"is-eq\",[[23,[\"selectedObject\"]],[22,1,[]]],null]],[9],[0,\"\\n      \"],[1,[22,1,[\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "managing-tool-frontend/components/select-object/template.hbs"
    }
  });

  _exports.default = _default;
});