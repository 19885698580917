define("managing-tool-frontend/we-manage/administration/customers/customer/machines/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function (controller, model) {
      this._super(controller, model);

      let customer = this.modelFor("weManage.administration.customers.customer");
      controller.set("customer", customer);
    },
    renderTemplate: function () {
      this.render("weManage/administration/customers/customer/machines/new", {
        into: "weManage/administration/customers",
        outlet: "newCustomerItems"
      });
    }
  });

  _exports.default = _default;
});