define("managing-tool-frontend/we-manage/administration/customers/customer/locations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let customer = this.modelFor("weManage.administration.customers.customer");
      controller.set("customer", customer);
    },

    model() {
      return this.modelFor("weManage.administration.customers.customer").get("locations");
    },

    afterModel(model) {
      model.forEach(function (location) {
        location.set("selected", false);
      });
    },

    renderTemplate() {
      this.render("weManage/administration/customers/customer/locations", {
        into: "weManage/administration/customers",
        outlet: "customerItems"
      });
    },

    actions: {
      loadLocation: function (location) {
        return this.transitionTo("weManage.administration.customers.customer.locations.location", location);
      },
      newLocation: function () {
        return this.transitionTo("weManage.administration.customers.customer.locations.new");
      }
    }
  });

  _exports.default = _default;
});