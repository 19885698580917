define("managing-tool-frontend/models/customer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    street: _emberData.default.attr(),
    number: _emberData.default.attr(),
    zipCode: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    country: _emberData.default.attr(),
    user: _emberData.default.hasMany("user"),
    machines: _emberData.default.hasMany("machine"),
    locations: _emberData.default.hasMany("location"),
    selected: false
  });

  _exports.default = _default;
});